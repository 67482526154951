import React from "react";
import Lottie from "lottie-react";

const AppLoader = () => {
  return (
    <div className="app-loader-animation" style={{zIndex: "10000", position: "fixed"}}>
      <Lottie
        animationData={require("../../assets/animation/progress.json")}
        loop
        autoplay
        className="app-loader"
      /> 
    </div>
  );
};

export default AppLoader;
