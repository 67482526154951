import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserLogin } from "../../context/AuthContext";
import { FaRegEye } from "react-icons/fa";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Dashboard from "../AdminView/Dashboard/Layout/dashboard";
import { ADMIN_URL } from "../../Auth_API";
import { TiArrowBack } from "react-icons/ti";

function AdminPIReport() {
    let navigate = useNavigate();
    let Organization_ID = localStorage.getItem("Sub_Admin_Id");
    const {
        setPIReportData,
        piReportData,
        setIsLoading,
        SubToken,
        levelCodes,
    } = UserLogin();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);
    const searchWords = searchQuery.split(" ");
    const piReportDataFiltered = piReportData?.filter((report) =>
        searchWords?.every(
            (word) => report?.user_name?.toLowerCase().includes(word.toLowerCase())
        )
    );

    useEffect(() => {
        const fetchReport = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(`${ADMIN_URL}/list_organization_predictive_index/${Organization_ID}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Token ${SubToken}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const sortedData = data?.data.sort((a, b) => new Date(b.submission_date) - new Date(a.submission_date));
                    setPIReportData(sortedData);
                    setIsLoading(false);
                } else {
                    console.error("Failed to fetch report data");
                    setIsLoading(false);
                }
            } catch (error) {
                console.error("Error while fetching report:", error);
                setIsLoading(false);
            }
        };

        fetchReport();
    }, []);

    const handleNaviagteToReport = (predictive_index_id, userEmail, userName) => {
        navigate("/candidatePI_Report", {
            state: {
                predictive_index_id,
                userEmail,
                userName,
            }
        });
        window.scrollTo(0, 0);
    }

    const columns = [
        { id: "id", label: "#", minWidth: 100 },
        { id: "user_name", label: "Candidate Name", minWidth: 100 },
        { id: "email", label: "Candidate Email", minWidth: 100 },
        { id: "personality_type", label: "Personality Type", minWidth: 100 },
        { id: "submission_date", label: "Submission Date", minWidth: 100 },
        { id: "move", label: "Action", minWidth: 100 },
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear().toString().slice(-2);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${month}/${day}/${year}`;
    };

    const handleBack = () => {
        navigate("/");
    }

    const getCellBackgroundColor = (index) => {
        return index % 2 === 0 ? "white" : "#F1F1F8"; // Alternating colors: white and light gray
    };

    return (
        <>
            <Dashboard title="Dashboard">
                <div className="my-5" style={{ padding: "10px 70px 0 70px" }}>
                    <div className="download-icon-container d-flex justify-content-between align-items-center mt-3">
                        <TiArrowBack size={32} className="text-right back-arrow-icon" onClick={handleBack} style={{ marginLeft: "-45px", marginTop: "-60px" }} />
                    </div>
                    <h2 className="text-center" style={{ marginTop: "-50px" }}>Predictive Reports</h2>
                    <Box sx={{ flexGrow: 1 }} className="predictive-margin">
                        {/* Search */}
                        <div className="search-area py-2 px-2 my-3" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Toolbar style={{ flexGrow: 1, justifyContent: 'flex-start' }}>
                                <form
                                    className="d-flex"
                                    role="search"
                                    style={{
                                        borderRadius: "18px",
                                        height: "60px",
                                        alignItems: "center",
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        marginLeft: "-70px"
                                    }}
                                >
                                    <div className={`search-container ${isExpanded ? "expanded" : ""}`} style={{ display: 'flex', alignItems: 'center' }}>
                                        <button className="search-button" style={{ border: 'none', background: 'transparent', marginRight: '8px' }}>
                                            <i className="fa fa-search"></i>
                                        </button>
                                        <input
                                            className="search-input"
                                            type="text"
                                            placeholder="Search"
                                            onBlur={() => setIsExpanded(false)}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                </form>
                            </Toolbar>
                        </div>

                        <Paper sx={{
                            width: {
                                xs: "130%",
                                sm: "130%",
                                md: '110%', // 100% width on small screens
                                xl: '100%', // Increase width on medium and larger screens
                            },
                            overflow: 'hidden',
                            marginLeft: {
                                xs: '-40px', // Apply margin only on small screens
                                md: '-35px',   // Reset margin on medium and larger screens
                            }
                        }} className="predictive-table">
                            <TableContainer sx={{ maxHeight: 1000 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead >
                                        <TableRow >
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align="left"
                                                    style={{
                                                        minWidth: column.minWidth,
                                                        background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)',
                                                        color: "white",
                                                        fontWeight: "700",
                                                        fontSize: "16px"
                                                    }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {piReportDataFiltered
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((user, index) => (
                                                <TableRow key={index}>
                                                    {columns.map((column, columnIndex) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align="left"
                                                            style={{
                                                                fontSize: "16px",
                                                                backgroundColor: getCellBackgroundColor(index)
                                                            }}
                                                        >
                                                            {column.id === "proficiency_level" ?
                                                                levelCodes[user[column.id]] || user[column.id] :
                                                                column.id === "move" ?
                                                                    <FaRegEye style={{ cursor: "pointer" }}
                                                                        onClick={() => handleNaviagteToReport(user.predictive_index_id, user.email, user.user_name)}
                                                                    />
                                                                    :
                                                                    column.id === "submission_date" ?
                                                                        formatDate(user.submission_date) :
                                                                        column.id === "id" ?
                                                                            index + 1 :
                                                                            user[column.id]
                                                            }
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                className="table-last-row-audio"
                                rowsPerPageOptions={[5, 10, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]}
                                component="div"
                                count={piReportDataFiltered.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />

                        </Paper>
                    </Box>
                </div>
            </Dashboard>
        </>
    );
}

export default AdminPIReport;
