export const AUTH_API_URL = "https://onboardiq.io/api/user_accounts";

export const INTERVIEW_API_URL = "http://192.168.18.29:8000/question";

export const REPORT_API_URL = "https://onboardiq.io/api/admin_accounts/test-submissions";

export const AUDIO_API_URL = "http://192.168.18.29:8000/test/english";

export const ADMIN_LOGIN_URL = "https://onboardiq.io/api/admin_login/admin-login/";

export const ADMIN_FORGET_URL = "https://onboardiq.io/api/admin_login/forgot-password/"

export const ADMIN_VERIFY_OTP = "https://onboardiq.io/api/admin_login/verify-otp/"

export const ADMIN_RESET_PASSWORD = "https://onboardiq.io/api/admin_login/reset-password/"

export const S_ADMIN_ALL_ORGANIZATION = "https://onboardiq.io/api/admin_login/organization/"

export const DEL_ADMIN_ORGANIZATION = "https://onboardiq.io/api/admin_login/organization/";

export const S_ADMIN_EDIT_ORGANIZATION = "https://onboardiq.io/api/admin_login/organization/";

export const S_ADMIN_ADD_ORGANIZATION = "https://onboardiq.io/api/admin_login/organization/create/";

export const ADMIN_TOGGLE_STATUS = "https://onboardiq.io/api/admin_login/organization/toggle_status/"

export const GET_ALL_USERS = "https://onboardiq.io/api/admin_login/sub_admins/";

export const ADD_SUB_ADMIN = "https://onboardiq.io/api/admin_login/add_sub_admin/";

export const DEL_SUB_ADMIN = "https://onboardiq.io/api/admin_login/sub_admins/delete/";

export const SUB_ADMIN_TOGGLE_STATUS = "https://onboardiq.io/api/admin_login/sub_admin/toggle_status/";

export const TOTAL_COUNT_SUB_ADMINS = "https://onboardiq.io/api/admin_login/total-active-subadmin/";

export const TOTAL_COUNT_USERS = "https://onboardiq.io/api/admin_login/total-users/";

export const TOTAL_COUNT_ORGANIZATION = "https://onboardiq.io/api/admin_login/total-organizations/";

export const TOTAL_COUNT_ORAL_ASSESSMENT = "https://onboardiq.io/api/admin_login/total-oral-test-submissions/";

export const TOTAL_COUNT_CVS = "https://onboardiq.io/api/admin_login/total-cvs/";

export const TOTAL_COUNT_PI = "https://onboardiq.io/api/admin_login/total-predictive-index/"

export const TOTAL_COUNT_REPORTS = "https://onboardiq.io/api/admin_login/total-predictive-index/"

export const GET_ACTIVE_ORGANIZATION = "https://onboardiq.io/api/admin_login/top-organizations/";

export const GET_ACTIVE_ADMINS = "https://onboardiq.io/api/admin_login/top-active-admins/"

export const GET_ADMIN_USER_PERCENTAGE = "https://onboardiq.io/api/admin_login/user-subadmin-percentages/"

// Sub Admin Api's

export const SUB_ADMIN_LOGIN = "https://onboardiq.io/api/admin_accounts/sub_admin_login/"

export const SUB_ADMIN_FIRST_LOGIN = "https://onboardiq.io/api/admin_accounts/sub_admin_reset_password/"

export const SUB_ADMIN_FORGET_PASSWORD_REQUEST = "https://onboardiq.io/api/admin_accounts/sub_admin_request_forget_password/"

export const SUB_ADMIN_VERFITY_OTP = "https://onboardiq.io/api/admin_accounts/sub_admin_verify_otp/"

export const SUB_ADMIN_RESET_PASSWORD = "https://onboardiq.io/api/admin_accounts/sub_admin_reset_pass/"

export const GET_LIST_TESTS_ADMIN = "https://onboardiq.io/api/admin_accounts/list_tests_admin/"

export const DEL_TEST = "https://onboardiq.io/api/admin_accounts/delete_test/"

export const CREATE_TEST = "https://onboardiq.io/api/admin_accounts/create_test/";

export const UPDATE_TEST = "https://onboardiq.io/api/admin_accounts/update_test/";

export const GET_TEST_QUESTIONS = "https://onboardiq.io/api/admin_accounts/test_detail_admin/"

export const DELETE_QUESTION = "https://onboardiq.io/api/admin_accounts/delete_question/"

export const UPDATE_QUESTION = "https://onboardiq.io/api/admin_accounts/update_question/"

export const IMG_URL = "https://onboardiq.io";

export const ADD_QUESTION = "https://onboardiq.io/api/admin_accounts/add_question/";

export const GET_SUB_ADMIN_TOTAL_USERS = "https://onboardiq.io/api/admin_accounts/total_users/"

export const GET_SUB_ADMIN_TOTAL_CV = "https://onboardiq.io/api/admin_accounts/total_cvs/"

export const GET_SUB_ADMIN_REPORTS = "https://onboardiq.io/api/admin_accounts/total_test_submissions/"

export const GET_SUBADMIN_PREDICTIVE_REPORTS = "https://onboardiq.io/api/admin_accounts/predictive-index-partner-count"

export const ADMIN_URL = "https://onboardiq.io/api/admin_accounts"