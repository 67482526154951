import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router";
import { UserLogin } from "../../../context/AuthContext";
import { SUB_ADMIN_FORGET_PASSWORD_REQUEST } from "../../../Auth_API";
import Progress from "../../Loader/Progress1";
import onbLogo from "../../../assets/img/onbLogoPng.png";
import polygonTL from "../../../assets/img/PolygonTopLeft.svg";
import polygonBL from "../../../assets/img/PolygonBottomLeft.svg";
import polygonBR from "../../../assets/img/PolygonBottomRight.svg";
import cellsTR from "../../../assets/img/cellsTopRight.svg";
import loginSearchImg from "../../../assets/img/loginSearchImg.png";
import loginStatsImg from "../../../assets/img/loginStatsPic.png";
import ArrowBack from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";

function FP_ADMIN_Screen1() {
  const [email_error, setEmail_Error] = useState(false);
  let navigation = useNavigate();
  const { isLoading, setIsLoading, showToast, setIsErrorOpen, setForgotUserEmail, } =
    UserLogin();
  const [credentials, setCredentials] = useState({
    email: "",
  });
  const { email } = credentials;
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const isValidEmail = (value) => {
    const regx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regx.test(value);
  };

  /* Function to reset password */
  const handleForgetPassword = async (e) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setEmail_Error(true)
      return
    }
    try {
      setIsLoading(true);
      const response = await fetch(`${SUB_ADMIN_FORGET_PASSWORD_REQUEST}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email
        }),
      });
      if (response.ok) {
        showToast("If you did not recieve an OTP, please check your spam!");
        showToast("Password reset OTP has been sent at your email!");
        setIsLoading(false);
        setForgotUserEmail(email);
        navigation("/sub_admin_forget_otp_password", {
          state: { forget_pass_email: email },
          replace: true,
        });
      } else {
        setIsErrorOpen(true);
        showToast("Password reset OTP not sent!");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Password reset OTP not sent!", error);
      setIsErrorOpen(true);
      showToast("Password reset OTP not sent!");
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="login-main">
        <div className="login-page-container d-flex justify-content-center gap-2 col-12">
          <div className="login-page-left d-flex flex-column justify-content-center col-md-6 col-12 ">
            <div className="login-page-form mt-3 d-flex flex-column align-items-center">
              <div className="col-9 ps-sm-4 ps-0">
                <img
                  src={onbLogo}
                  alt="logo"
                  className="login-form-logo"
                />
              </div>
              <div className="login-form-container col-sm-9 col-11 mt-1 shadow-none">
                <div className="login-form-body">
                  <div className="d-flex align-items-center mb-2">
                    <ArrowBack onClick={() => navigation("/")} style={{ cursor: "pointer" }} />
                    <p className="m-0 p-0" onClick={() => navigation("/")} style={{ cursor: "pointer", color: "grey" }}> Back to log in</p>
                  </div>
                  <h4 className="fw-bold text-capitalize mt-4">Reset your password</h4>
                  <p className="m-0 p-0 text-secondary">
                    Please enter your email address to search for your account
                  </p>
                  <hr />
                  <div className="login-form-input mt-3 d-flex flex-column gap-2">
                    <label style={{color:"#212529"}}>Email Address (Required)</label>
                    <input
                      placeholder="johndoe@gmail.com"
                      name="email"
                      value={email}
                      onChange={onChange}
                      margin="normal"
                      required
                      autoComplete="off"
                      type="text"
                      className="col-12"
                      error={email_error}
                    />
                    {/* {error && !isValidEmail(email) && (
                      <span className="error-fields text-danger ms-2">Enter a valid email!</span>
                    )} */}

                    {email_error&&"Enter a valid email!"}
                  </div>

                  <button
                    onClick={handleForgetPassword}
                    className={`login-form-button col-12 mt-3 ${isLoading ? "disabled" : ""}`}
                    disabled={isLoading}
                  >
                    Next
                  </button>
                  {isLoading ? <Progress /> : null}
                </div>
              </div>
            </div>
          </div>
          <div className="login-page-right bg-danger my-3 col-md-5 d-md-block d-none">
            <img src={loginSearchImg} alt="login-search-img" className="login-search-img" />
            <img src={polygonTL} alt="polygon" className="polygon-top-left" />
            <img src={cellsTR} alt="cells" className="cells-top-right" />
            <img src={polygonBL} alt="polygon" className="polygon-bottom-left" />
            <img src={polygonBR} alt="polygon" className="polygon-bottom-right" />
            <img src={loginStatsImg} alt="stats" className="login-stats" />
          </div>
        </div>
      </div>


    </>
  );
}

export default FP_ADMIN_Screen1;

