import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { UserLogin } from "../../../context/AuthContext";
import { SUB_ADMIN_RESET_PASSWORD } from "../../../Auth_API";
import onbLogo from "../../../assets/img/onbLogoPng.png";
import polygonTL from "../../../assets/img/PolygonTopLeft.svg";
import polygonBL from "../../../assets/img/PolygonBottomLeft.svg";
import polygonBR from "../../../assets/img/PolygonBottomRight.svg";
import cellsTR from "../../../assets/img/cellsTopRight.svg";
import loginSearchImg from "../../../assets/img/loginSearchImg.png";
import loginStatsImg from "../../../assets/img/loginStatsPic.png";
import { Link } from "react-router-dom";
import ArrowBack from "@mui/icons-material/ArrowBackIos";
import CheckIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";

import Progress from "../../Loader/Progress1";
function FP_ADMIN_Screen3() {
  let navigation = useNavigate();
  const location = useLocation();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showToastMsg,setShowToastMsg] = useState(true);
  const { reset_pass_email, otp_admin } = location.state || {};
  const {
    isLoading,
    setIsLoading,
    error,
    setError,
    updateError,
    isValidObjField,
    showToast,
    setIsErrorOpen,
    showPassword,
    setShowPassword,
    forgotUserEmail, 
    forgotUserEmailOTP
  } = UserLogin();
  const [PassbtnEnable, setPassBtnEnable] = useState(true);
  const [credentials, setCredentials] = useState({
    old_password: "",
    new_password: ""
  });

  const { old_password, new_password } = credentials;

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "new_password" || name === "old_password") {
      setPassBtnEnable(value !== credentials.old_password);
    }
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleToggleNewPassword = () => {
    setShowNewPassword((prevShowPassword) => !prevShowPassword);
  };
  /* Function to reset password */
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      if (!isValidObjField(credentials)) {
        return updateError("Require all fields!", setError);
      }
      if (!old_password.trim() || old_password.length < 5) {
        return updateError("Password must be 5 character long!", setError);
      }
      if (!new_password.trim() || new_password.length < 5) {
        return updateError("Password must be 5 character long!", setError);
      }

      setIsLoading(true);
      const response = await fetch(`${SUB_ADMIN_RESET_PASSWORD}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // email: reset_pass_email, // not working from this email state
          // otp: otp_admin, //not working from this otp state
          email: forgotUserEmail,
          otp: forgotUserEmailOTP, // working - new context email otp 
          n_password: new_password,
        }),
      });
      if (response.ok) {
        showToast("Your Password changed. Please Login to continue!");
        navigation("/");
        setIsLoading(false);
      } else {
        showToast("Failed to reset password!");
        setIsErrorOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      showToast("Failed to reset password!");
      setIsLoading(false);
    }
  };


  return (
    <>
      <div className="login-main">
        <div className="login-page-container d-flex justify-content-center gap-2 col-12">
          <div className="login-page-left d-flex flex-column justify-content-center col-md-6 col-12 ">
            <div className="login-page-form mt-3 d-flex flex-column align-items-center">
              <div className="col-9 ps-4">
                <img
                  src={onbLogo}
                  alt="logo"
                  className="login-form-logo"
                />
              </div>
              <div className="login-form-container col-9 mt-1 shadow-none">
                <div className="login-form-body">
                  <div className="d-flex align-items-center mb-2">
                    <ArrowBack onClick={() => navigation("/")} style={{ cursor: "pointer" }} />
                    <p
                      className="m-0 p-0"
                      onClick={() => navigation("/")}
                      style={{ cursor: "pointer", color: "grey" }}
                    >
                      Back to log in
                    </p>
                  </div>
                  <div
                    className={`col-11 justify-content-evenly align-items-center rounded-1 py-2 ${showToastMsg ? "d-flex" : "d-none"}`}
                    style={{
                      opacity: "0.9",
                      background: "rgba(80, 180, 130, 0.12)",
                    }}
                  >
                    <CheckIcon fontSize="small" />
                    <p className="m-0 p-0"> OTP has been verfied.</p>
                    <CloseIcon fontSize="small" onClick = {()=> setShowToastMsg(false)} />
                  </div>
                  <h4 className="fw-bold text-capitalize mt-3">Reset your password</h4>
                  <p className="m-0 p-0 text-secondary">Please enter your new password.</p>
                  <hr />

                  <div className="login-form-input mt-3 d-flex flex-column gap-2">
                    <label>Password</label>
                    <div className="col-12 password-textfield p-0 m-0">
                      <TextField
                        className="col-12 m-0"
                        type={showPassword ? "text" : "password"}
                        name="old_password"
                        id="old_password"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                        placeholder="Password*"
                        value={old_password}
                        onChange={(e)=>{onChange(e)}}
                        margin="normal"
                        aria-autocomplete="none"
                        required
                        autoComplete="off"
                        error={error && (!old_password.trim() || old_password.length < 5)}
                        helperText={
                          error &&
                          (!old_password.trim() || old_password.length < 5) &&
                          "New Password must be at least 5 characters long!"
                        }
                        // onFocus={handlePasswordFocus}
                        // onBlur={handlePasswordBlur}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleTogglePassword} edge="end">
                                {showPassword ? (
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                    style={{ fontSize: "16px" }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-eye-slash"
                                    aria-hidden="true"
                                    style={{ fontSize: "16px" }}
                                  ></i>
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                 

                  <div className="login-form-input mt-3 d-flex flex-column gap-2">
                    <label>Confirm Password</label>
                    <div className="col-12 password-textfield p-0 m-0">
                      <TextField
                        type={showPassword ? "text" : "password"}
                        placeholder="Confirm Password*"
                        name="new_password"
                        className="col-12 m-0"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                        id="new_password"
                        value={new_password}
                        onChange={(e)=>{onChange(e)}}
                        margin="normal"
                        required
                        autoComplete="off"
                        error={error && (!new_password.trim()||new_password.length<5)}
                        helperText={error && (!new_password.trim || new_password<5)&& "Confirm Password must be atleast 5 characters long"}
                       
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleToggleNewPassword} edge="end">
                                {showNewPassword ? (
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                    style={{ fontSize: "16px" }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-eye-slash"
                                    aria-hidden="true"
                                    style={{ fontSize: "16px" }}
                                  ></i>
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <button
                    onClick={handleResetPassword}
                    className={`login-form-button col-12 mt-3 ${isLoading ? "disabled" : ""}`}
                    disabled={isLoading}
                  >
                    Reset password
                  </button>

                  {isLoading ? <Progress /> : null}
                </div>
              </div>
            </div>
          </div>
          <div className="login-page-right bg-danger my-3 col-md-5 d-md-block d-none">
            <img src={loginSearchImg} alt="login-search-img" className="login-search-img" />
            <img src={polygonTL} alt="polygon" className="polygon-top-left" />
            <img src={cellsTR} alt="cells" className="cells-top-right" />
            <img src={polygonBL} alt="polygon" className="polygon-bottom-left" />
            <img src={polygonBR} alt="polygon" className="polygon-bottom-right" />
            <img src={loginStatsImg} alt="stats" className="login-stats" />
          </div>
        </div>
      </div>
    </>
  );
}

export default FP_ADMIN_Screen3;
