import React from "react";
import { Route, Routes } from "react-router-dom";
import { UserLogin } from "../context/AuthContext";
import FP_SUB_ADMIN_Screen1 from "../components/AuthScreens/SubAdminForgetPassword/FP_SUB_ADMIN_Screen1"
import FP_SUB_ADMIN_Screen3 from "../components/AuthScreens/SubAdminForgetPassword/FP_SUB_ADMIN_Screen3";
import FP_SUB_ADMIN_Screen2 from "../components/AuthScreens/SubAdminForgetPassword/FP_SUB_ADMIN_Screen2";
import Sub_AdminLogin from "../components/SubAdminView/SubAdminAuth/LoginSmallTalk"
import Dashboard from "../components/AdminView/Dashboard/main";
import FirstTimeLogin from "../components/SubAdminView/SubAdminAuth/FirstTimeLogin"
import OralAssessment from "../components/AdminView/Dashboard/OralAssessment/OralAssessment";
import { Navigate } from "react-router-dom";
import ErrorPage from "../ErrorPage/ErrorPage";
import AdminReport from "../components/AdminReport/AdminReport";
import SelectedCandidateReport from "../components/AdminReport/SelectedCandidateReport";
import SelectedReportDropdown from "../components/AdminReport/SelectedReportDropdown";
import AdminPIReport from "../components/AdminReport/AdminPIReport";
import PIReportMain from "../components/AdminReport/SubAdminPredictiveReport/PIReportMain";
import PIReportTableMain from "../components/AdminReport/SubAdminPredictiveReport/PIReportTableMain";
import SelectedReport from "../components/MyReports/SelectedReport"
function SubAdminStack() {
  const { SubToken, FirstLogin } = UserLogin();

  return (
    <div style={{ fontFamily: "Roboto, sans-serif" }}>
      <>
        <Routes>
          <Route exact path="/" element={FirstLogin ? (<FirstTimeLogin />) : (SubToken ? <Dashboard /> : <Sub_AdminLogin />)} />
          <Route exact path="/sub_admin_forget_password" element={<FP_SUB_ADMIN_Screen1 />} />
          <Route exact path="/sub_admin_forget_otp_password" element={<FP_SUB_ADMIN_Screen2 />} />
          <Route exact path="/sub_admin_reset_password" element={<FP_SUB_ADMIN_Screen3 />} />
          <Route exact path="/main" element={<Dashboard />} />
          <Route exact path="/main/oral_assessment" element={<OralAssessment />} />
          <Route exact path="/errorpage" element={<ErrorPage />} />
          <Route exact path="/reports" element={<AdminReport />} />
          <Route exact path="/predictive_reports" element={<AdminPIReport />} />
          <Route exact path="/predictive_table_reports" element={<PIReportTableMain />} />
          <Route exact path="/candidatePI_Report" element={<PIReportMain />} />
          <Route exact path="/selectedReport" element={<SelectedReport />} />
          <Route exact path="/candidateReport" element={<SelectedReport />} />
          <Route path="*" element={SubToken && <Navigate to="/errorpage" />} />
        </Routes>
      </>
    </div>
  );
}

export default SubAdminStack;
