import React from "react";
import Lottie from "lottie-react";

const Progress = () => {
  return (
    <div className="cv-progress-animation" style={{position:"absolute"}}>
      <Lottie
        animationData={require("../../assets/animation/progress.json")}
        loop
        autoplay
        className="cv-progress" style={{width:"auto", height:"auto"}}
      />
    </div> 
  );
};

export default Progress;
