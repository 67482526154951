import React, { useEffect } from "react";
import { Toolbar } from "@mui/material";
import { IoIosShareAlt } from "react-icons/io";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserLogin } from "../../../context/AuthContext";
import { ADMIN_URL } from "../../../Auth_API";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import arrow from "../../../assets/img/arrow-partner.png";

const DashFoot = () => {
  let navigate = useNavigate();
  let Organization_ID = localStorage.getItem("Sub_Admin_Id")
  const {
    reportData,
    setReportData,
    setIsLoading,
    SubToken,
    capitalizeFirst,
    setTestName
  } = UserLogin();
  const [searchQuery, setSearchQuery] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const searchWords = searchQuery.split(" ");
  const reportDataFiltered = reportData.filter((report) =>
    searchWords.every(
      (word) => {
        const username = report.user_name || "";
        return username.toLowerCase().includes(word.toLowerCase());
      }
    )
  );

  /* Function to get the Reports for user */
  useEffect(() => {
    const fetchReport = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${ADMIN_URL}/oral-test-submissions/${Organization_ID}`, {
          method: "GET",
          headers: {
            Authorization: `Token ${SubToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          const modifiedData = data.data.map(item => ({
            ...item,
            submission_date: new Date(item.submission_date)
          })).sort((a, b) => b.submission_date - a.submission_date);
          setReportData(modifiedData);

          if (data.data.length > 0) {
            setTestName(data.data[0].test_name);
          }
          setIsLoading(false);
        } else {
          console.error("Failed to fetch report data");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error while fetching report:", error);
        setIsLoading(false);
      }
    };

    fetchReport();
  }, []);

  const handleNaviagteToReport = (testSubmissionId, userEmail, userName, userTestName) => {
    navigate("/selectedReport", {
      state: {
        testSubmissionId,
        userEmail,
        userName,
        userTestName
      }
    });
    window.scrollTo(0, 0);
  };

  const handleNaviagteToPIReport = (predictive_index_id, userEmail, userName) => {
    navigate("/predictive_table_reports", {
      state: {
        predictive_index_id,
        userEmail,
        userName,
      }
    });
    window.scrollTo(0, 0);
  };

  return (
    <div style={{ paddingBottom: "30px" }}>
      <div
        className="dashboard-footer mt-lg-0 mt-3 shadow-sm pb-4"
        style={{ backgroundColor: "#FFFFFF" }} data-tour="8"
      >
        <div className="search-area py-2 px-2 my-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Toolbar style={{ flexGrow: 1, justifyContent: 'flex-end' }}>
            <form
              className="d-flex"
              role="search"
              style={{
                borderRadius: "18px",
                backgroundColor: "#FFFFFF",
                height: "60px",
                alignItems: "center",
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div className={`search-container ${isExpanded ? "expanded" : ""}`} style={{ display: 'flex', alignItems: 'center'}}>
                <button className="search-button" style={{ border: 'none', background: 'transparent', marginRight: '8px' }}>
                  <i className="fa fa-search"></i>
                </button>
                <input
                  className="search-input"
                  type="text"
                  placeholder="Search"
                  onBlur={() => setIsExpanded(false)}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </form>
          </Toolbar>
        </div>

        {/* Dashboard User Table */}
        <TableContainer
          component={Paper}
          style={{ maxHeight: 550, overflowY: 'auto',borderRadius:"6px" }}
          className="custom-scrollbar"
        >

          <Table stickyHeader aria-label="sticky table" >
          <TableHead
             
            >
              <TableRow >
                <TableCell
                  style={{
                    background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)',
                    color: "white",
                    fontWeight: "700",
                    fontSize: "16px"
                  }}
                >
                #
                </TableCell>
                <TableCell
                  style={{
                    background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)',
                    color: "white",
                    fontWeight: "700",
                    fontSize: "16px"
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                    background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)',
                    color: "white",
                    fontWeight: "700",
                    fontSize: "16px"
                  }}
                >
                  Email</TableCell>
                <TableCell
                  style={{
                    background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)',
                    color: "white",
                    fontWeight: "700",
                    fontSize: "16px"
                  }}
                >
                  Assessment Score
                </TableCell>
                <TableCell
                  style={{
                    background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)',
                    color: "white",
                    fontWeight: "700",
                    fontSize: "16px"
                  }}
                >Assessment Name
                </TableCell>
                <TableCell
                  style={{
                    background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)',
                    color: "white",
                    fontWeight: "700",
                    fontSize: "16px"
                  }}
                >
                  Predictive Index
                </TableCell>
                <TableCell
                  style={{
                    background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)',
                    color: "white",
                    fontWeight: "700",
                    fontSize: "16px"
                  }}
                >
                  Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {reportDataFiltered.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{capitalizeFirst(item.user_name)}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{capitalizeFirst(item.proficiency_level)}</TableCell>
                  <TableCell>{item.test_name}</TableCell>
                  <TableCell>{item.predictive_index_test_given ?
                    <button className="pi-taken"
                      onClick={() => handleNaviagteToPIReport(item.predictive_index_id, item.email, item.user_name)}

                    >Taken</button> : <button className="pi-not-taken">Not Taken</button>}</TableCell>
                  <TableCell>
                    <img
                      src={arrow} // Adjust the path to your local image file
                      alt="Share"
                      onClick={() => handleNaviagteToReport(item.test_submission_id, item.email, item.user_name, item.test_name)}
                      style={{ cursor: 'pointer', width: '24px', height: '24px' }} // Adjust the size as needed
                    />
                  </TableCell>

              
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    </div >
  );
};

export default DashFoot;
