import React, { useState } from "react";
import rocket from "../../../assets/img/rocket.png";
import "./sub_admin.css";
import registerLogo from "../../../assets/img/register-logo.png";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { UserLogin } from "../../../context/AuthContext";
import AppLoader from "../../Loader/AppLoader";
import { SUB_ADMIN_FIRST_LOGIN } from "../../../Auth_API";
import MainLogo from "../../../assets/tab-logo.png";
import Swal from "sweetalert2";
import axios from "axios";
// import { useTour } from "@reactour/tour";

export default function LoginSmallTalk() {
  // const { setIsOpen } = useTour();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    old_password: "",
    new_password: ""
  });

  const { old_password, new_password } = credentials;
  const {
    isLoading,
    setIsLoading,
    isValidObjField,
    updateError,
    error,
    setError,
    showPassword,
    setShowPassword,
    showToast,
    setFirstLogin,
    sub_email,
    sub_password
  } = UserLogin();
  const [btnEnable, setBtnEnable] = useState(true);
  // Function to login a user
  const handleLogin = async (e) => {
    e.preventDefault()
    if (!isValidObjField(credentials)) {
      return updateError("Require all fields!", setError);
    }
    if (!old_password.trim() || old_password.length < 5) {
      return updateError("Password must be 5 character long!", setError);
    }
    if (!new_password.trim() || new_password.length < 5) {
      return updateError("Password must be 5 character long!", setError);
    }
    setIsLoading(true);
    const response = await axios.post(`${SUB_ADMIN_FIRST_LOGIN}`, {
      email: sub_email,
      temp_password: sub_password,
      new_password: credentials.new_password
    }, {
      headers: {
        "Content-Type": "application/json",
      }
    }).then((response) => {
      showToast(`${response.data.message}`)
      setFirstLogin(false);
      localStorage.setItem("FirstLogin", true);
      setCredentials({ old_password: "", new_password: "" })
      showToast("Password set successfully!");
      // setIsOpen(true)
    }).catch((error) => {
      Swal.fire({
        icon: "error",
        title: `${error.response.data.error}`,
        text: "Please verify your credentials.",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Verify Credentials",
      })
      setIsLoading(false);

    })
    setIsLoading(false);

    // if (response.ok) {
    //   const data = await response.json();
    //   setCredentials({ email: "", temp_password: "", ne_password: "" })
    //  console.log(data)
    //   // showToast("You have been Login successfully!");
    //   // navigation("/sub_admin_dashboard");
    //   // console.log("User login successfully:", data);
    //   // setIsLoading(false);
    // } else {
    //   const data = await response.json();
    //   console.error("Account not verified. Please verify your account.",error);
    //   Swal.fire({
    //     icon: "error",
    //     title: `${data.message}`,
    //     text: "Please verify your account.",
    //     showCancelButton: true,
    //     cancelButtonText: "Cancel",
    //     confirmButtonText: "Verify Account",
    //   })
    //   // .then((result) => {
    //   //   if (result.isConfirmed) {
    //   //     handleVerifyAccount();
    //   //   }
    //   // });
    //   setIsLoading(false);
    // }
  };
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleToggleNewPassword = () => {
    setShowNewPassword((prevShowPassword) => !prevShowPassword);
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "new_password" || name === "old_password") {
      setBtnEnable(value !== credentials.old_password);
    }
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const isValidEmail = (value) => {
    const regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regx.test(value);
  };

  // Function to verify account
  return (
    <>
      <div
        className="login-bg"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <div className="bg-blur-login">
          <div className="login-card card">
            {/* CDS logo */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "-30px",
              }}
            >
              <img
                src={MainLogo}
                width="35%"
                height="35%"
                alt="CDS logo"
              />
            </div>
            <div className="card-body mt-3">
              <div className="d-flex justify-content-center">
                <h4>Set New Password</h4>
              </div>
              <form className="form-container">
                <div className="image-container">
                  <img src={rocket} alt="img" height={150} width={150} />
                </div>
                <TextField
                  label="New Password"
                  type={showPassword ? "text" : "password"}
                  name="old_password"
                  value={old_password}
                  onChange={(e) => { onChange(e) }}
                  margin="normal"
                  sx={{ width: "100%" }}
                  required
                  autoComplete="off"
                  error={error && (!old_password.trim() || old_password.length < 5)}
                  helperText={
                    error &&
                    (!old_password.trim() || old_password.length < 5) &&
                    "New Password must be at least 5 characters long!"
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleTogglePassword()} edge="end">
                          {/* Eye icon */}
                          {showPassword ? (
                            <i
                              className="fa fa-eye"
                              aria-hidden="true"
                              style={{ fontSize: "16px" }}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-eye-slash"
                              aria-hidden="true"
                              style={{ fontSize: "16px" }}
                            ></i>
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Confirm Password"
                  type={showNewPassword ? "text" : "password"}
                  name="new_password"
                  value={new_password}
                  onChange={onChange}
                  margin="normal"
                  sx={{ width: "100%" }}
                  required
                  autoComplete="off"
                  error={error && (!new_password.trim() || new_password.length < 5)}
                  helperText={
                    error &&
                    (!new_password.trim() || new_password.length < 5) &&
                    "Confirm Password must be at least 5 characters long!"
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => { handleToggleNewPassword() }} edge="end">
                          {/* Eye icon */}
                          {showNewPassword ? (
                            <i
                              className="fa fa-eye"
                              aria-hidden="true"
                              style={{ fontSize: "16px" }}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-eye-slash"
                              aria-hidden="true"
                              style={{ fontSize: "16px" }}
                            ></i>
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <button
                  onClick={handleLogin}
                  className={`button button--flex mt-5 ${btnEnable ? 'disable-btn' : ''}`} disabled={btnEnable}
                >
                  Set Password
                </button>
              </form>
            </div>
            {/* <AppLoader /> */}
            {isLoading ? <AppLoader /> : null}
          </div>
        </div>
      </div>

    </>
  );
}
