import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { UserLogin } from "../../../context/AuthContext";
import vocabularyImg from "../../../assets/img/analytics.png";

function ReportSection4() {
  const { selectedAudioReport, levelCodes, levelNames } = UserLogin();
  const wordsByLevel = selectedAudioReport?.analysis_data?.detailedMetrics?.WordsByLevel;
  const percentageByLevel = selectedAudioReport?.analysis_data?.detailedMetrics?.PercentageByLevel;
  const activeVocabulary =
    selectedAudioReport?.analysis_data?.detailedMetrics?.vocabulary?.activeVocabulary;
  const highestLevel = selectedAudioReport?.analysis?.proficiencyLevel;
  const levelCode = levelCodes[highestLevel] || "A1";
  const levelOrder = Object.values(levelCodes);
  const currentLevelIndex = levelOrder.indexOf(levelCode);
  const nextLevelCode =
    currentLevelIndex >= 0 && currentLevelIndex < levelOrder.length - 1
      ? levelOrder[currentLevelIndex + 1]
      : null;
  const totalUniqueWords =
    selectedAudioReport?.analysis_data?.detailedMetrics?.vocabulary?.uniqueWords;
  const rareWords = selectedAudioReport?.analysis_data?.detailedMetrics?.vocabulary?.rareWords;
  const frequentlyUsedWords =
    selectedAudioReport?.analysis_data?.detailedMetrics?.vocabulary?.frequentlyUsedWords;

  const [chartData, setChartData] = useState({
    series: [],
    color: ["#43ADDB", "green", "yellow", "orange", "purple"],
    options: {
      chart: {
        type: "polarArea",
        width: 380,
      },
      labels: [],
      fill: {
        opacity: 1,
      },
      stroke: {
        width: 0,
      },
      legend: {
        // position: "bottom",
        // itemMargin: {
        //   horizontal: 0,
        //   vertical: 10,
        // },
        // position:"bottom",
        formatter: function (val, opts) {
          return `${opts.w.globals.series[opts.seriesIndex]}% — ${val}`;

        },
      },
      yaxis: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 1280,
          options: {
            chart: {
              height: 350,
              width:300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
        {
          breakpoint: 760,
          options: {
            chart: {
              height: 5000,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const [hasChartData, setHasChartData] = useState(false);

  useEffect(() => {
    if (percentageByLevel) {
      const newSeries = [];
      const newLabels = [];
      const colors = []; // Define base colors

      Object.entries(percentageByLevel).forEach(([levelCode, percentage], index) => {
        const percentageNumber = percentage !== "N/A" ? parseFloat(percentage) : 0;
        newSeries.push(percentageNumber);
        const levelName = levelNames[levelCode];
        const label = `${levelName} (${levelCode})`;
        newLabels.push(label);
      });

      const hasData = newSeries.some((value) => value !== 0);
      setHasChartData(hasData);

      setChartData((prevData) => ({
        ...prevData,
        series: newSeries,
        options: {
          ...prevData.options,
          labels: newLabels,
          colors: colors.slice(0, newSeries.length),
          dataLabels: {
            enabled: false,
          },
        },
      }));
    }
  }, [percentageByLevel, levelNames]);

  return (
    <div>
      {/* Vocabulary content Statistics & Level Table*/}
      <div className="row mb-3">
        <div className="col-md-12">
          <div
            className="card"
            style={{ borderRadius: "22px", padding: "20px", border: "2px solid #b7b7b7" }}
          >
            <div className="card-body">
              <div className="d-flex flex-md-row gap-5 px-0 pe-0 flex-column align-items-center align-items-md-start">
                {/* Pie chart */}
                <div className="col-md-6 col-lg-6 col-12 d-flex flex-column align-items-sm-start align-items-center ">
                  <div style={{ fontWeight: "bold", padding: "15px 0" }}>
                    <h3 className="card-title mx-sm-2 mx-0 text-start" style={{ fontWeight: "600" }}>
                      Vocabulary Statistics
                    </h3>
                  </div>
                  {hasChartData ? (
                    <div className="report-chart col-12 d-flex flex-column align-items-center px-0">
                      <ReactApexChart
                        options={chartData.options}
                        series={chartData.series}
                        type="polarArea"
                        height={300}
                        width={450}
                      />
                    </div>
                  ) : (
                    <p className="card-text mx-3">Vocabulary statistics data is N/A</p>
                  )}
                </div>

                {/* word level table */}
                <div
                  className="col-md-6 col-lg-5 col-sm-12 col-12"
                  style={{
                    borderRadius: "14px",
                  }}
                >
                  <div style={{ fontWeight: "bold", padding: "12px 0" }}>
                    <h3 className="card-title mx-2" style={{ fontWeight: "600" }}>
                      Word Sample By Level
                    </h3>
                  </div>
                  <div className="table-responsive">
                    <table className="table" style={{ color: "#606070", borderRadius: "18px" }}>
                      <thead>
                        <tr>
                          <th style={{ marginTop: "10%" }} scope="col">
                            Level
                          </th>
                          <th scope="col" style={{ padding: "0 0 7px 50px", marginTop: "10%" }}>
                            Words
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {wordsByLevel &&
                          Object.keys(wordsByLevel).map((level, index) => (
                            <tr key={index} className={`table ${index % 2 !== 0 ? "bg" : ""}`}>
                              <th scope="row">{levelCodes[level] || level}</th>
                              <td style={{ padding: "5px 0 5px 50px" }}>
                                {wordsByLevel[level].join(", ")}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* Vocabulary content 4 small div's */}
            </div>
          </div>
          <div className="grid-container-voc mb-3 mt-3 print-voc">
            <div className="grid-item-voc card">
              <div className="card-body">
                <p className="voc-titles">Active Vocabulary</p>
                <h5 className="card-title" style={{ color: "#56367F" }}>
                  <b className="fs-3">
                    {activeVocabulary}&nbsp;<sub className="text-dark fs-6">words</sub>
                  </b>
                </h5>
                <p className="card-text">
                  Corresponds to level {levelCode}, the next level{" "}
                  {nextLevelCode ? `${nextLevelCode}` : "is not defined"} starts with{" "}
                  {activeVocabulary}&nbsp; words
                </p>
              </div>
            </div>

            <div className="grid-item-voc card">
              <div className="card-body">
                <p className="voc-titles">Unique words</p>
                <h5 className="card-title" style={{ color: "#56367F" }}>
                  <b className="fs-3">
                    {totalUniqueWords}&nbsp;<sub className="text-dark fs-6">words</sub>
                  </b>
                </h5>
                <p className="card-text">that are used only once in your complete speech</p>
              </div>
            </div>

            <div className="grid-item-voc card">
              <div className="card-body">
                <p className="voc-titles">Rare words</p>
                <h5 className="card-title" style={{ color: "#56367F" }}>
                  <b className="fs-3">
                    {rareWords}%&nbsp;<sub className="text-dark fs-6">words</sub>
                  </b>
                </h5>
                <p className="card-text">words that are not among the 5,000 most common English</p>
              </div>
            </div>

            <div className="grid-item-voc card">
              <div className="card-body">
                <p className="voc-titles">Frequently used </p>
                <h5 className="card-title" style={{ color: "#56367F" }}>
                  <b className="fs-3">
                    {frequentlyUsedWords}&nbsp;<sub className="text-dark fs-6">words</sub>
                  </b>
                </h5>
                <p className="card-text">
                  words that are among the 2,000 most frequently used English words
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportSection4;
