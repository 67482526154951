import React, { useEffect, useState } from 'react';
import footerImg1 from "../../../assets/img/pi-5.png";
import AppLoader from '../../Loader/AppLoader';
import Dashboard from "../../AdminView/Dashboard/Layout/dashboard";
import { ADMIN_URL } from '../../../Auth_API';
import { useLocation, useNavigate } from 'react-router';
import { UserLogin } from '../../../context/AuthContext';
import Section0 from './PISections/Section0';
import Section1 from './PISections/Section1';
import Section2 from './PISections/Section2';
import Section3 from './PISections/Section3';
import Section4 from './PISections/Section4';
import Section5 from './PISections/Section5';
import Section6 from './PISections/Section6';
import { TiArrowBack } from 'react-icons/ti';

function PIReportMain() {
    let navigate = useNavigate();
    const {
        SubToken,
        setPISelectedReport,
    } = UserLogin();
    const location = useLocation();
    const predictive_index_id = location.state?.predictive_index_id;
    console.log(predictive_index_id, "hxjvgxv")
    const userEmail = location.state?.userEmail;
    const userName = location.state?.userName;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // Endpoint integration to fetch PI report
    const fetchReport = async () => {
        setLoading(true);
        setError('');
        if (!predictive_index_id) {
            console.log("No Predictive Index ID found.");
            setError("No Predictive Index ID found.");
            setLoading(false);
            return;
        }
        try {
            const response = await fetch(`${ADMIN_URL}/user_predictive_index_report/${predictive_index_id}/report`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${SubToken}`
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data, "dfdgfd")
            setPISelectedReport(data);
        } catch (error) {
            console.error("Failed to fetch report:", error);
            setError("Failed to fetch report. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (predictive_index_id) {
            fetchReport();
        }
    }, [predictive_index_id]);


    if (loading) {
        return <div>
            <AppLoader />
        </div>;
    }

    const handleBack = () => {
        navigate("/predictive_reports")
    }

    return (
        <>
            <Dashboard title="Dashboard">
                {/* <p className='pi-title px-5'>Predictive Report</p> */}
                <div className="download-icon-container d-flex justify-content-between align-items-center mt-3">
                    <TiArrowBack size={32} className="text-right back-arrow-icon" onClick={handleBack} />
                </div>
                <div style={{ padding: "45px 0px 0px 0px" }}>
                    <div className="row mx-1">
                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                            <div style={{ textAlign: "center", margin: "auto" }}>
                                <span style={{
                                    fontWeight: "bold", fontSize: "30px",
                                }}>Predictive Report</span>
                                <p className="text-right">
                                    <b>Candidate Name: </b>
                                    {userName}<br />
                                    <b>Candidate Email: </b>
                                    {userEmail}
                                </p>
                            </div>

                        </div>
                    </div>
                </div>

                <Section0 />
                <Section1 />
                <Section2 />
                <Section3 />
                <Section4 />
                <Section5 />
                <Section6 />
                {/* <img src={footerImg1} alt='Footer image' className='pi-top-img px-3' /> */}
            </Dashboard>
        </>
    );
}

export default PIReportMain;
