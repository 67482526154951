import React, { createContext, useContext, useState, useEffect } from "react";
import { Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  // Add the new state for managing tabs or any other value state
  const [value, setValue] = useState(0); // New state variable

  // Existing states and functions
  const [stickyNav, setstickyNav] = useState(false);
  const [toTop, settoTop] = useState(false);
  const [active, setActive] = useState(0);
  const [activeCV, setActiveCV] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [timerValue, setTimerValue] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [forgotUserEmail, setForgotUserEmail] = useState("");
  const [forgotUserEmailOTP, setForgotUserEmailOTP] = useState("");

  const [reportData, setReportData] = useState([]);
  const [piReportData, setPIReportData] = useState([]);
  const [interviewName, setInterviewName] = useState("");
  const [selectedAudioReport, setSelectedAudioReport] = useState([]);
  const [piSelectedReport, setPISelectedReport] = useState([]);

  const storedToken = localStorage.getItem("token");
  const [token, setToken] = useState(storedToken);

  const getSubToken = localStorage.getItem("SubToken");
  const [SubToken, setSubToken] = useState(getSubToken);
  const storedUserData = localStorage.getItem("userData");
  const [FirstLogin, setFirstLogin] = useState(false);
  const [FirstLoginCheck, setFirstLoginCheck] = useState(false);
  const getSubAdminId = localStorage.getItem("Sub_Admin_Id");
  const [SubAdminId, setSubAdminId] = useState(getSubAdminId);
  const [testName, setTestName] = useState("");

  const [protectRoute, setProtectRoute] = useState(false);

  let initialUserData;
  try {
    initialUserData = storedUserData ? JSON.parse(storedUserData) : {};
  } catch (error) {
    console.error("Error parsing storedUserData:", error);
    initialUserData = {};
  }

  const [userData, setUserData] = useState(initialUserData);

  const [language, setLanguage] = useState("");
  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [pi_id, setpi_id] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [race, setRace] = useState("");
  const [education, setEducation] = useState("");
  const [radio11, setRadio11] = useState("");
  const [radio12, setRadio12] = useState("");
  const [radio13, setRadio13] = useState("");
  const [checkbox1_pi, setCheckbox1_pi] = useState([]);
  const [checkbox2_pi, setCheckbox2_pi] = useState("");
  const [getSuperToken] = useState(localStorage.getItem("SuperToken"));
  const [superToken, setSuperToken] = useState(getSuperToken);
  const [sub_email, setSubEmail] = useState("");
  const [sub_password, setSubPassword] = useState("");
  const [adminEmail, setAdminEmail] = useState(localStorage.getItem("adminEmail"));

  const navigation = useNavigate();

  /***** Functions to format timer for interview ******/
  useEffect(() => {
    let intervalId;
    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTimerValue((prevValue) => {
          if (prevValue >= 3600) {
            setIsTimerRunning(false);
            return 3600;
          }
          return prevValue + 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isTimerRunning]);

  const formatTimer = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    const formattedMins = mins < 10 ? `0${mins}` : mins;
    const formattedSecs = secs < 10 ? `0${secs}` : secs;
    return `${formattedMins}:${formattedSecs}`;
  };

  /***** Validation Checks ******/
  const isValidObjField = (obj) => {
    return Object.values(obj).every((value) => value.trim());
  };

  const updateError = (error, stateUpdater) => {
    stateUpdater(error);
    setTimeout(() => {
      stateUpdater("");
    }, 2500);
  };

  /***** Showing Toast/Alert ******/
  const showToast = (message) => {
    setToastMessage(message);
    setIsToastOpen(true);
  };

  const handleToastClose = () => {
    setIsToastOpen(false);
  };

  const handleErrorClose = () => {
    setIsErrorOpen(false);
  };

  /* Function to capitalize first letter of name */
  const capitalizeFirst = (str) => {
    if (typeof str === "string") {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  };

  /* Description for each Levels */
  const levelDescriptions = {
    beginner:
      "At the beginner level, individuals are starting to grasp basic conversational phrases. They can exchange simple greetings and engage in uncomplicated discussions about topics like the weather, daily routines, and personal hobbies. While they may stumble occasionally, they are eager to learn and improve their ability to participate in everyday chit-chat with friends and acquaintances.",

    elementary:
      "Elementary-level practitioners are gaining confidence in their conversational skills. They can comfortably initiate and sustain dialogues on familiar subjects, including personal interests, favorite foods, and leisure activities. While they might occasionally seek help with complex vocabulary, they're well on their way to becoming adept at handling light-hearted exchanges and social interactions.",

    intermediate:
      "Individuals at the intermediate level of exhibit proficiency in engaging discussions. They can confidently express opinions, share experiences, and discuss a broad array of topics, such as travel destinations, recent movies, and upcoming plans. Their ability to navigate conversations fluidly and respond thoughtfully makes them valuable contributors to social gatherings and casual conversations.",

    upperintermediate:
      "At the upper-intermediate level, enthusiasts display finesse in steering conversations. They can delve into deeper subjects, such as cultural trends, personal goals, and societal issues, while maintaining a comfortable and engaging atmosphere. Their conversational prowess allows them to connect with others on a meaningful level, making them sought-after conversationalists.",

    advanced:
      "Advanced practitioners are virtuosos in the art of conversation. They effortlessly navigate intricate topics, including philosophy, art, and global affairs, while weaving in personal anecdotes and insightful perspectives. Their eloquence and charm make them captivating interlocutors who can turn any exchange into a memorable and enriching experience.",
  };

  /* Display Highest Level in Polygon */
  const levelCodes = {
    Beginner: "A1",
    Elementary: "A2",
    Intermediate: "B1",
    upperintermediate: "B2",
    Advanced: "C1",
    Proficiency: "C2",
  };

  const levelNames = {
    A1: "Beginner",
    A2: "Elementary",
    B1: "Intermediate",
    B2: "Upper-intermediate",
    C1: "Advanced",
    C2: "Proficiency",
  };

  /***** Get and Store CSRF Token in localStorage ******/
  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  }, [token]);

  /***** Protected Routing ******/
  useEffect(() => {
    if (!SubToken) {
      if (
        window.location.pathname.startsWith("/sub_admin_forget_password") ||
        window.location.pathname.startsWith("/sub_admin_forget_otp_password") ||
        window.location.pathname.startsWith("/sub_admin_reset_password")
      ) {
        navigation(window.location.pathname);
      } else {
        navigation("/");
      }
    }
  }, [SubToken, navigation]);
  useEffect(() => {
    const handlePopstate = () => {
      if (protectRoute) {
        navigation("/main");
      } else {
        navigation("/");
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [protectRoute]);

  return (
    <AuthContext.Provider
      value={{
        userData,
        setUserData,
        token,
        setToken,
        superToken,
        isValidObjField,
        updateError,
        showToast,
        error,
        setError,
        setIsErrorOpen,
        capitalizeFirst,
        isLoading,
        setIsLoading,
        showPassword,
        setShowPassword,
        stickyNav,
        setstickyNav,
        toTop,
        settoTop,
        active,
        setActive,
        activeCV,
        setActiveCV,
        interviewName,
        setInterviewName,
        reportData,
        setReportData,
        piReportData,
        setPIReportData,
        selectedAudioReport,
        setSelectedAudioReport,
        piSelectedReport, setPISelectedReport,
        levelDescriptions,
        levelNames,
        timerValue,
        setTimerValue,
        isTimerRunning,
        setIsTimerRunning,
        formatTimer,
        levelDescriptions,
        adminEmail,
        setAdminEmail,
        levelCodes,
        language,
        fname,
        mname,
        lname,
        email,
        pi_id,
        age,
        gender,
        race,
        education,
        radio11,
        radio12,
        radio13,
        checkbox1_pi,
        checkbox2_pi,
        setCheckbox1_pi,
        setCheckbox2_pi,
        setLanguage,
        setFname,
        setMname,
        setLname,
        setEmail,
        setpi_id,
        setAge,
        setGender,
        setRace,
        setEducation,
        setRadio11,
        setRadio12,
        setRadio13,
        setSuperToken,
        SubToken,
        setSubToken,
        FirstLogin,
        setFirstLogin,
        setSubAdminId,
        SubAdminId,
        testName,
        setTestName,
        FirstLoginCheck,
        setFirstLoginCheck,
        setSubEmail,
        setSubPassword,
        sub_email,
        sub_password,
        isErrorOpen,
        setProtectRoute,
        forgotUserEmail,
        setForgotUserEmail,
        forgotUserEmailOTP,
        setForgotUserEmailOTP,
        value, // New state added
        setValue, // New state setter added
      }}
    >
      {children}
      {/* Success Toast Component */}
      <Snackbar
        open={isToastOpen}
        autoHideDuration={3000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" onClose={handleToastClose}>
          {toastMessage}
        </Alert>
      </Snackbar>
      {/* Error Toast Component */}
      <Snackbar
        open={isErrorOpen}
        autoHideDuration={3000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" onClose={handleErrorClose}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </AuthContext.Provider>
  );
};

export const UserLogin = () => useContext(AuthContext);

export default AuthProvider;
