import React, { useEffect, useState, useRef } from "react";
import { UserLogin } from "../../context/AuthContext";
import { REPORT_API_URL } from "../../Auth_API";
import ReportSection1 from "./ReportSections/ReportSection1";
import ReportSection2 from "./ReportSections/ReportSection2";
import ReportSection3 from "./ReportSections/ReportSection3";
import ReportSection4 from "./ReportSections/ReportSection4";
import ReportSection5 from "./ReportSections/ReportSection5";
import ReportSection6 from "./ReportSections/ReportSection6";
import AppLoader from "../Loader/AppLoader";
import Dashboard from "../AdminView/Dashboard/Layout/dashboard";
import Lottie from "lottie-react";
import footerImg from "../../assets/img/pi-5.png";
import { useLocation, useNavigate } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import generatePDF from "react-to-pdf";

export default function SelectedReport() {
  const {
    testName,
    userData,
    selectedAudioReport,
    setSelectedAudioReport,
    value,
    SubToken,
    setValue, // Assuming setValue is also provided by UserLogin context
    isLoading,
    setIsLoading,
  } = UserLogin();
  const { token } = userData || {};
  let Organization_ID = localStorage.getItem("Sub_Admin_Id");
  const [error, setError] = useState("");
  const totalWords = selectedAudioReport?.total_words_count;
  const totalDuration = selectedAudioReport?.total_audio_duration;
  const minutes = Math.floor(totalDuration / 60);
  const seconds = totalDuration % 60;
  const submissionId = localStorage.getItem("submissionId");

  const targetRef = useRef();
  const location = useLocation();
  let navigate = useNavigate();
  const testSubmissionId = location.state?.testSubmissionId;
  const userEmail = location.state?.userEmail;
  const userName = location.state?.userName;
  const userTestName = location.state?.userTestName;

  useEffect(() => {
    console.log("sub id", submissionId);
  });

  useEffect(() => {
    const fetchReport = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${REPORT_API_URL}/${Organization_ID}/${testSubmissionId}/report`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${SubToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setSelectedAudioReport(data);
          console.log(data, "report g");
        } else {
          const errorData = await response.json();
          setError(errorData.error || "Failed to fetch report data");
          console.log();
        }
      } catch (error) {
        setError("Error while fetching report: " + error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (Organization_ID) {
      fetchReport();
    }
  }, [Organization_ID]);

  if (isLoading) {
    return <AppLoader />;
  }

  const handlePrint = () => {
    targetRef.current.classList.add("print-only");
    window.print();
    targetRef.current.classList.remove("print-only");
  };


  const handleBack = () => {
    navigate("/");
  };

  if (!selectedAudioReport || error) {
    return (
      <div style={{ padding: "45px" }}>
        <div
          className="card"
          style={{
            borderRadius: "25px",
            background: "linear-gradient(180deg, #56367f 0%, #3a5697 100%)",
          }}
        >
          <div className="card-body mx-4">
            <div
              className="d-flex flex-lg-row flex-column align-items-center justify-content-center"
              style={{ padding: "25px 0", display: "flex" }}
            >
              <h4 className="card-title text-white text-center mt-lg-0 mt-5">
                You did not speak enough words. Your report is not generated.
              </h4>
              <Lottie
                animationData={require("../../assets/animation/noOral.json")}
                loop
                autoplay
                style={{ width: "15%", marginLeft: "-20px" }}
                className="img-fluid dynamic-img"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const cardContent = [
    <ReportSection4 key="vocabulary" />,
    <ReportSection5 key="pronunciation" />,
    <ReportSection6 key="grammar" />,
  ];

  if (selectedAudioReport && !error) {
    return (
      <Dashboard title="Dashboard">
        <div className="px-3">
          <div className="download-icon-container oral-report d-flex justify-content-between align-items-center">
            <TiArrowBack size={32} className="text-right back-arrow-icon" onClick={handleBack} />
            <Lottie
              animationData={require("../../assets/animation/download1.json")}
              loop
              autoplay
              className="download-icon px-3"
              onClick={handlePrint}
            />
          </div>
          <div ref={targetRef}>
            <div className="oral-container">
              <div className="row mx-1 d-flex justify-content-between align-items-center">
                <div className="col-md-9">
                  <span style={{ fontWeight: "bold", fontSize: "22px" }}>{userTestName}</span>
                  <p className="mx-1">
                    <span style={{ fontWeight: "600" }}>Duration:</span>{" "}
                    {isNaN(minutes) ? 0 : minutes} Min {isNaN(seconds) ? 0 : seconds} Sec &nbsp;{" "}
                    <span style={{ fontWeight: "600" }}>Words: </span>
                    {totalWords}
                  </p>
                </div>
                <div className="col-md-3">
                  <p className="text-right">
                    <b>Candidate Name: </b>
                    {userName}
                    <br />
                    <b>Candidate Email: </b>
                    {userEmail}
                  </p>
                </div>
              </div>
            </div>
            <div className="oral-container ms-md-0 ms-3  ">
              <ReportSection1 />
              <ReportSection2 />
              {/* <ReportSection3 /> */}
              <div className="page-content" />
              <ReportSection4 key="vocabulary" />
              <ReportSection5 key="pronunciation" />
              <ReportSection6 key="grammar" />
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
  return null;
}
