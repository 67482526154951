import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { GrFormClose } from "react-icons/gr";
import MainLogo from "../../../../assets/img/onboardiq.svg";
import { useTour } from "@reactour/tour";
import dashboard from "../../../../assets/img/dashboard.png";
import oral from "../../../../assets/img/oral-assess.png";
import report from "../../../../assets/img/assess-reports.png";
import guide from "../../../../assets/img/guide.png";
import logout from "../../../../assets/img/logout.png";
import { TbReportSearch } from "react-icons/tb";
import { RxCaretDown, RxCaretUp } from "react-icons/rx";
import { UserLogin } from "../../../../context/AuthContext";
import LogoutModal from '../Layout/logoutadmin'; // Import the modal component

const Sidebar = ({ setToggle, setsideToggle }) => {
  const { setSubToken, setProtectRoute, adminEmail } = UserLogin();
  const { setIsOpen } = useTour();
  const location = useLocation();
  const [openTab, setOpenTab] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false); // State for modal visibility
  const sidebarRef = useRef(null); // Ref for the sidebar

  const onClose = () => {
    setsideToggle(false);
  };

  const handleNavigate = () => {
    setIsOpen(true);
  };

  const isActive = (path) => location.pathname === path;

  const onAdminLogout = () => {
    localStorage.removeItem("SubToken");
    localStorage.removeItem("Sub_Admin_Id");
    setSubToken(null);
    const initialState = { path: "/" };
    window.history.replaceState(initialState, "", "/");
    setProtectRoute(false);
    setShowLogoutModal(false); // Close the modal after logout
  };

  const handleLogoutClick = () => {
    if (window.innerWidth <= 768) {
      setsideToggle(false); // Close the sidebar on mobile screens
    }
    setShowLogoutModal(true); // Open the modal
  };

  useEffect(() => {
    // Function to close sidebar if click is outside
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setsideToggle(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setsideToggle]);

  return (
    <div
      ref={sidebarRef} // Attach the ref to the sidebar
      className={`sidebar ${setToggle ? "open-sidebar" : ""}`}
      style={{ backgroundColor: "#F5F5F5" }}
    >
      <div className="close-div">
        <GrFormClose onClick={onClose} />
      </div>
      <div className="side-title text-center">
        <NavLink to="/main">
          <img src={MainLogo} className="img-fluid" alt="CDS" />
        </NavLink>
      </div>
      <div className="side-content mt-4 d-flex flex-column">
        <ul className="navbar-nav">
          <li className={`nav-items-2 py-3 px-3 mx-3 align-items-center ${isActive('/main') ? 'active' : ''}`}>
            <NavLink to="/main" className="nav-link py-0 d-flex align-items-center gap-2">
              <img src={dashboard} alt="dashboard" /> Dashboard
            </NavLink>
          </li>
          {location.pathname !== "/reports" && location.pathname !== "/predictive_reports" && (
            <li className={`nav-items-2 py-3 px-3 mx-3 align-items-center ${isActive('/guide') ? 'active' : ''}`}>
              <NavLink
                className="nav-link py-0 d-flex align-items-center gap-2"
                onClick={() => {
                  onClose();
                  setTimeout(handleNavigate, 1000);
                }}
              >
                <img src={guide} alt="guide" /> Guide
              </NavLink>
            </li>
          )}
          <li className={`nav-items-2 py-3 px-3 mx-3 align-items-center ${isActive('/main/oral_assessment') ? 'active' : ''}`}>
            <NavLink to="/main/oral_assessment" className="nav-link py-0 d-flex align-items-center gap-2">
              <img src={oral} alt="oral assessment" /> Add Oral Assessment
            </NavLink>
          </li>
          <li className="py-0 nav-link">
            <ul className={`navbar-nav report-nav-tabs-dropdown ${openTab && "open-report-dropdown"}`}>
              <li className="report-dropdown nav-items-2 py-3 px-3 mx-3" onClick={() => setOpenTab(!openTab)}>
                <span className="d-flex align-items-center gap-2">
                  <img src={report} alt="report" /> Assessment Reports
                </span>
                {openTab ? <RxCaretUp className="report-dropdown-icon" /> : <RxCaretDown className="report-dropdown-icon" />}
              </li>
              <li className={`report-dropdown nav-items-2 py-3 px-3 mx-3 pt-3 ${isActive('/predictive_reports') ? 'active' : ''}`}>
                <NavLink to="/predictive_reports" className="align-items-center text-white">
                  <span>
                    <TbReportSearch style={{ position: "relative", top: "-3px",color:"white" }} /> Predictive Index
                  </span>
                </NavLink>
              </li>
              <li className={`report-dropdown nav-items-2 py-3 px-3 mx-3 pt-3 ${isActive('/reports') ? 'active' : ''}`}>
                <NavLink to="/reports " className="text-white" >
                  <span>
                    <TbReportSearch style={{ position: "relative", top: "-3px",color:"white" }} /> Oral Assessment
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
        <ul className="navbar-nav" style={{ fontWeight: "normal" }}>
          <li className={`nav-items-2 py-3 px-3 mx-3 align-items-center ${isActive('/') ? 'active' : ''}`}>
            <NavLink to="#" className="nav-link py-0 d-flex align-items-center gap-2" onClick={handleLogoutClick}>
              <img src={logout} alt="logout" /> Logout
            </NavLink>
          </li>
          <li className={`nav-items-2 py-3 px-3 mx-3 align-items-center`}>
            <span className="nav-link py-0 d-flex align-items-center gap-2">
              <p className="admin-email email-in-sidebar">{adminEmail}</p>
            </span>
          </li>
        </ul>
      </div>
      {showLogoutModal && (
        <LogoutModal
          onClose={() => setShowLogoutModal(false)}
          onConfirm={onAdminLogout}
        />
      )}
    </div>
  );
};

export default Sidebar;
