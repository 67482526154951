import React from 'react';
import human from "../../../../assets/img/dj1.png";
import trophy from "../../../../assets/img/trophy.svg";
import { UserLogin } from '../../../../context/AuthContext';

function Section0() {
    const { piSelectedReport } = UserLogin();

    return (
        <>
            <div className='pi-section-container'>
                <div className="pi-card card mb-3" style={{ width: "100%", padding: "60px 100px",backgroundColor:"white" }}>
                    <div className="row g-0 pi-dynamic-row pi-dynamic-small-row">
                        <div className="col-md-10 col-sm-12 pi-col-5 pi-personality">
                            <div className="card-text pi-card-text mt-5 pi-grid-section0" >
                                <div>
                                    <img
                                        src={trophy}
                                        alt="..."
                                        className='trophy'
                                    />
                                </div>
                                <div>
                                    <span className='mx-1'>Personality Type: {" "}
                                        <span style={{ color: "#56367F", fontSize: "32px" }}>
                                            {piSelectedReport?.analysis_data?.personality_type}
                                        </span>
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12 pi-col-7">
                            <img
                                src={human}
                                alt="..."
                                className='pi-human'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Section0