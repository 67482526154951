import React, { useState } from 'react';
import { UserLogin } from '../../../context/AuthContext';
import micHuman from "../../../assets/img/pronunciation.png";

function CandReportSection4_2() {
    const { selectedAudioReport } = UserLogin();
    const transcriptions = selectedAudioReport?.transcriptions;
    const punctuationMistakes = selectedAudioReport?.analysis_data?.detailedMetrics?.punctuationMistakes;
    const [expandText, setExpandText] = useState({});

    const formatDuration = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${mins} Min ${secs} Sec`;
    };

    const playPronunciation = (word) => {
        if ('speechSynthesis' in window) {
            const utterance = new SpeechSynthesisUtterance(word);
            utterance.lang = 'en-US';
            window.speechSynthesis.speak(utterance);
        } else {
            alert("Speech synthesis not supported in this browser.");
        }
    };

    const highlightMistakes = (text) => {
        let highlightedText = text;
        if (punctuationMistakes) {
            Object.entries(punctuationMistakes).forEach(([mistake, correction]) => {
                const regex = new RegExp(mistake, 'gi');
                highlightedText = highlightedText.replace(regex, (match) => {
                    return `<span style="color: red; cursor: pointer;" onclick="playPronunciation('${correction}')" title="Listen"><u>${match}</u> <i class='fa fa-volume-up'></i></span>`;
                });
            });
        }
        return highlightedText;
    };
    window.playPronunciation = playPronunciation;


    const toggleText = (index) => {
        setExpandText(prevState => ({ ...prevState, [index]: !prevState[index] }));
    };

    const createMarkup = (text, index) => {
        const htmlText = highlightMistakes(text);
        const needExpand = text.split(' ').length > 50;

        if (expandText[index] || !needExpand) {
            return { __html: htmlText };
        } else {
            const words = htmlText.split(' ');
            const shownText = words.slice(0, 50).join(' ') + '...';
            return { __html: shownText };
        }
    };

    return (
        <>
            <div className='row' style={{ background: "rgb(249, 249, 250)", padding: "12px 20px", borderRadius: "20px" , pageBreakBefore: 'always',pageBreakAfter: 'always'}}>
                <div className='col'>
                    <div style={{ fontWeight: "bold", padding: "25px 0" }}>
                        <h4 className="card-title" style={{ fontWeight: "700" }}>
                            <img src={micHuman} alt='trophy img' className='pronunciation-img' /> &nbsp;
                            Pronunciation
                        </h4>
                        <br /><br />
                        <h9 className="my-5" style={{ backgroundColor: "pink" }}>
                            <u>Pronunciation mistakes</u>
                        </h9>
                        &nbsp; &nbsp;
                        <h9 style={{ backgroundColor: "#f5e06c" }}>
                            <u>Phonetic inaccuracies that change the meaning</u>
                        </h9>
                    </div>
                    <div className="row mb-3">
                        {transcriptions?.map((item, index) => (
                            <div key={index} className="col-md-6 card-text mb-2 h-100">
                                <div>
                                    <div className='oral-pronunciation-qs pb-2'>
                                        <div className="pronunciation-speaking-titles">Question {index + 1}:</div>
                                        <div className="pronunciation-speaking">
                                        Duration: {formatDuration(item.duration)}
                                        </div> 
                                    </div>
                                    <span className='pronunciation-speaking-details'>{item.question_description}</span>
                                    <br /><br />
                                </div>
                                <div>
                                    <div className='pronunciation-speaking-titles'>Answer: </div>
                                    <span
                                        style={{ height: "100%", color: "#787878" }}
                                        dangerouslySetInnerHTML={createMarkup(item.transcription || '', index)}
                                    />
                                    {item.transcription && item.transcription.split(' ').length > 50 && (
                                        <span onClick={() => toggleText(index)}
                                            style={{
                                                cursor: 'pointer', color: 'black',
                                                fontWeight: "700",
                                            }}
                                        >
                                            {expandText[index] ? ' Read Less' : ' Read More'}
                                        </span>
                                    )}
                                    <br /><br />
                                    <hr />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CandReportSection4_2;
