import React, { useState } from "react";
import rocket from "../../../assets/img/rocket.png";
import "./sub_admin.css";
import "../../../App.css";
import registerLogo from "../../../assets/img/register-logo.png";
import onbLogo from "../../../assets/img/onbLogoPng.png";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { UserLogin } from "../../../context/AuthContext";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import AppLoader from "../../Loader/AppLoader";
import { SUB_ADMIN_LOGIN } from "../../../Auth_API";
import polygonTL from "../../../assets/img/PolygonTopLeft.svg";
import polygonBL from "../../../assets/img/PolygonBottomLeft.svg";
import polygonBR from "../../../assets/img/PolygonBottomRight.svg";
import cellsTR from "../../../assets/img/cellsTopRight.svg";
import loginSearchImg from "../../../assets/img/loginSearchImg.png";
import loginStatsImg from "../../../assets/img/loginStatsPic.png";
import Progress from "../../Loader/Progress1";
// import { useTour } from "@reactour/tour";

import axios from "axios";

export default function LoginSmallTalk() {
  // const { setIsOpen } = useTour();
  let navigation = useNavigate();
  const {
    isLoading,
    setIsLoading,
    isValidObjField,
    updateError,
    error,
    setError,
    showPassword,
    setShowPassword,
    showToast,
    setIsErrorOpen,
    setSubToken,
    setFirstLogin,
    setSubEmail,
    setSubPassword,
    setProtectRoute,
    setAdminEmail
  } = UserLogin();
  
  const [credentials, setCredentials] = useState({
    sub_email: "",
    password: "",
  });
  
  const { sub_email, password } = credentials;

  // Function to login a user
  const handleLogin = async (e) => {
    e.preventDefault();
    
    if (!isValidObjField(credentials)) {
      return updateError("Require all fields!", setError);
    }
    if (!isValidEmail(sub_email)) {
      return updateError("Enter a valid email!", setError);
    }
    if (!password.trim() || password.length < 5) {
      return updateError("Password must be at least 5 characters long!", setError);
    }
    
    // Convert email to lowercase
    const lowerCaseEmail = sub_email.toLowerCase();
    
    setSubEmail(lowerCaseEmail);
    setSubPassword(password);
    setIsLoading(true);

    try {
      const response = await axios.post(`${SUB_ADMIN_LOGIN}`, {
        email: lowerCaseEmail,
        password: password
      }, {
        headers: {
          "Content-Type": "application/json",
        }
      });

      if (response.data.first_login) {
        setIsLoading(false);
        showToast(`${response.data.message}`);
        setIsErrorOpen(true);
        setFirstLogin(response.data.first_login);
      } else {
        setSubToken(response.data.token);
        console.log(response.data);
        localStorage.setItem("SubToken", response.data.token);
        localStorage.setItem("Sub_Admin_Id", response.data.org_id);
        localStorage.setItem("adminEmail", lowerCaseEmail);
        setAdminEmail(lowerCaseEmail);
        setCredentials({ sub_email: "", password: "" });
        showToast("You have been logged in successfully!");
        navigation("/main");
        setIsLoading(false);
        setProtectRoute(true);
        // setIsOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
      showToast(`${error.response.data.error}`);
      setIsErrorOpen(true);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const isValidEmail = (value) => {
    const regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regx.test(value);
  };

  return (
    <div className="login-main">
      <div className="login-page-container d-flex justify-content-center gap-2 col-12">
        <div className="login-page-left d-flex flex-column justify-content-center col-md-6 col-12">
          <div className="login-page-form mt-3 d-flex flex-column align-items-center">
            <div className="col-9 ps-4">
              <img
                src={onbLogo}
                alt="logo"
                className="login-form-logo"
              />
            </div>
            <div className="login-form-container col-9 mt-1 shadow-none">
              <div className="login-form-body">
                <h4 className="fw-bold text-capitalize">Admin Login</h4>
                <p className="m-0 p-0 text-secondary">
                 Please login with your account details
                </p>
                <hr />
                <div className="login-form-input mt-3 d-flex flex-column gap-2">
                  <label style={{color:"#212529"}}>Email Address (Required)</label>
                  <input
                    name="sub_email"
                    id="email"
                    value={credentials.sub_email}
                    onChange={onChange}
                    margin="normal"
                    required
                    autoComplete="off"
                    type="text"
                    placeholder="Email"
                    className="col-12"
                  />
                  {error && !isValidEmail(credentials.sub_email) && (
                    <span className="error-fields text-danger ms-2">
                      Enter a valid email!
                    </span>
                  )}
                </div>
                <div className="login-form-input mt-3 d-flex flex-column gap-2">
                  <label style={{color:"#212529"}}>Password</label>
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      className="form-control password"
                      name="password"
                      id="password"
                      value={password}
                      onChange={onChange}
                      margin="normal"
                      autoComplete="off"
                      required
                    />
                    <span className="input-group-text" onClick={handleTogglePassword}>
                      {showPassword ? (
                        <i
                          className="fa fa-eye text-secondary"
                          aria-hidden="true"
                          style={{ fontSize: "16px", cursor: "pointer" }}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye-slash text-secondary"
                          aria-hidden="true"
                          style={{ fontSize: "16px", cursor: "pointer" }}
                        ></i>
                      )}
                    </span>
                  </div>
                  {error && (!password.trim() || password.length < 5) && (
                    <span className="error-fields text-danger ms-2">
                      Password must be at least 5 characters long!
                    </span>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-start mt-2">
                  <Link
                    className="text-decoration-none mt-1"
                    style={{ color: "#56367f", fontSize: "16px", fontWeight: "600" }}
                    to="/sub_admin_forget_password"
                  >
                    Forgot password?
                  </Link>
                </div>

                <button
                  onClick={handleLogin}
                  className={`login-form-button col-12 mt-3 ${isLoading ? "disabled" : ""}`}
                  disabled={isLoading}
                >
                  Login
                </button>
                {isLoading ? <Progress /> : null}
              </div>
            </div>
          </div>
        </div>
        <div className="login-page-right bg-danger my-3 col-md-5 d-md-block d-none">
          <img src={loginSearchImg} alt="login-search-img" className="login-search-img" />
          <img src={polygonTL} alt="polygon" className="polygon-top-left" />
          <img src={cellsTR} alt="cells" className="cells-top-right" />
          <img src={polygonBL} alt="polygon" className="polygon-bottom-left" />
          <img src={polygonBR} alt="polygon" className="polygon-bottom-right" />
          <img src={loginStatsImg} alt="stats" className="login-stats" />
        </div>
      </div>
    </div>
  );
}
