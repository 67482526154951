import React, { useEffect, useState } from "react";
import Dashboard from "../Layout/dashboard";
import "./assessment.css";
import axios from "axios";
import { UserLogin } from "../../../../context/AuthContext";
import customCloseIcon from "../../../../assets/img/cross.png";
import {
  GET_LIST_TESTS_ADMIN,
  DEL_TEST,
  CREATE_TEST,
  UPDATE_TEST,
  GET_TEST_QUESTIONS,
  DELETE_QUESTION,
  UPDATE_QUESTION,
  IMG_URL,
  ADD_QUESTION,
} from "../../../../Auth_API";
import { FaTrash } from "react-icons/fa";
import { LiaEditSolid } from "react-icons/lia";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import Swal from "sweetalert2";
import MoreAppLoader from "../../../Loader/MoreAppLoader";
import { Divider } from "@mui/material";
import { useTour } from "@reactour/tour";

const OralAssessment = () => {
  const { SubToken, showToast, setIsLoading, isLoading, setIsErrorOpen } = UserLogin();
  const [showAttentionModal, setShowAttentionModal] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [imgModal, setImgModal] = useState(false);
  const [questionModal, setQuestionModal] = useState(false);
  const get_sub_admin_id = localStorage.getItem("Sub_Admin_Id");
  const [heightToggle, setHeightToggle] = useState(-1);
  const [heightState, setHeightState] = useState(false);
  const [listTest, setListTest] = useState({});
  const [editId, setEditid] = useState(null);
  const [questionName, setQuestionName] = useState("");
  const [qId, setQId] = useState(null);
  const [addQid, setAddQId] = useState(null);
  const [listQId, setListQId] = useState(null);
  const [imgState, setImgState] = useState("");
  const [testQuestions, setTestQuestions] = useState({});
  const [editQuestionModal, setEditQuestionModal] = useState(false);
  const [delBtnDisable, setDelBtnDisable] = useState(true);
  const [addQuestionModal, setAddQuestionModal] = useState(false);
  const [addBtnDisable, setAddBtnDisable] = useState(true);
  const [oldName, setOldName] = useState("");
  const [oldDesc, setOldDesc] = useState("");
  const { isOpen, currentStep, setCurrentStep, setIsOpen } = useTour();

  // useEffect(() => {
  //   console.log("current step", currentStep);
  //   if (currentStep < 3 ) {
  //     setCurrentStep(3);
  //   }
  // }, [currentStep]);
  // Functions to disable and enable scrolling
  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableScroll = () => {
    document.body.style.overflow = '';
  };

  useEffect(() => {
    console.log("current step", currentStep);
    if (currentStep < 3) {
      setCurrentStep(3);
    }
  }, [currentStep]);

  useEffect(() => {
    const handleBodyScroll = () => {
      if (isOpen) {
        disableScroll();
      } else {
        enableScroll();
        setCurrentStep(3);
      }
    };

    handleBodyScroll();

    return () => {
      enableScroll(); // Ensure scroll is enabled when the component unmounts
    };
  }, [isOpen, setCurrentStep]);


  const [editCredentials, setEditCredentials] = useState({
    name: "",
    description: "",
  });

  const [addCredentials, setAddCredentials] = useState({
    name: "",
    description: "",
  });

  const [formData, setFormData] = useState({
    type: "text",
    text: "",
    image: null,
    description: "",
  });

  const [addformData, setAddFormData] = useState({
    type: "",
    text: "",
    image: null,
    description: "",
  });

  const [addMultiData, setAddMultiData] = useState([]);
  const saveMultiQuestion = (type, text, image, description) => {
    if (
      (type !== "" && text !== "" && description !== "") ||
      (type !== "" && image !== null && description !== "")
    ) {
      setAddMultiData([
        ...addMultiData,
        {
          type: type,
          text: text,
          image: image,
          description: description,
        },
      ]);
      setAddFormData({
        type: "",
        text: "",
        image: null,
        description: "",
      });
      setAddBtnDisable(false);
      setDelBtnDisable(false);
    } else {
      setIsErrorOpen(true);
      showToast("All Fields Required");
    }
  };

  useEffect(() => {
    if (addMultiData.length === 0) {
      setDelBtnDisable(true);
      setAddBtnDisable(true);
    }
  }, [addMultiData]);

  const addhandleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setAddFormData({
        ...addformData,
        [name]: files[0],
        text: "",
      });
    } else {
      setAddFormData({
        ...addformData,
        [name]: value,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setFormData({
        ...formData,
        [name]: files[0],
        text: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const onChangeCredentials = (e) => {
    const { name, value } = e.target;
    setAddCredentials({ ...addCredentials, [name]: value });
  };

  const onChangeEditCredentials = (e) => {
    const { name, value } = e.target;
    setEditCredentials({ ...editCredentials, [name]: value });
  };

  // Endpoint integration
  const getTestLists = async () => {
    await axios
      .get(`${GET_LIST_TESTS_ADMIN}${get_sub_admin_id}/`, {
        headers: {
          Authorization: `Token ${SubToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setListTest(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onClickAddQuestionBtn = (id) => {
    setAddFormData({
      type: "",
      text: "",
      image: null,
      description: "",
    });
    setAddQuestionModal(true);
    setAddQId(id);
  };

  const onClickHeightToggle = (index) => {
    if (heightToggle === index) {
      setHeightToggle(-1);
      setHeightState(false);
    } else {
      setHeightToggle(index);
      setHeightState(true);
    }
  };

  // Endpoint integration of delete test
  const onClickDeleteTest = async (id) => {
    setIsLoading(true);
    const response = await axios.delete(`${DEL_TEST}${id}/`, {
      headers: {
        Authorization: `Token ${SubToken}`,
        "Content-Type": "application/json",
      },
    });
    if (response.status === 204) {
      showToast("Test Deleted Successfully");
      getTestLists();
    }
    setIsLoading(false);
  };

  const onClickUpdateTest = async (id) => {
    setEditid(id);
    setEditModal(true);
    console.log("id", id);
    const filteredTest = listTest.filter((test) => test.id === id);
    setOldName(filteredTest[0].name);
    setOldDesc(filteredTest[0].description);
  };

  // Endpoint integration of submit test
  const onSubmitTest = async (e) => {
    e.preventDefault();
    setShowModal(false);
    setIsLoading(true);
    await axios
      .post(
        `${CREATE_TEST}`,
        {
          name: addCredentials.name,
          description: addCredentials.description,
        },
        {
          headers: {
            Authorization: `Token ${SubToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setAddCredentials({
          name: "",
          description: "",
        });
        setShowModal(false);
        showToast("Test Created Succesfully");
        getTestLists();
        setShowAttentionModal(true);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Test Created Request Reject",
          text: "Failed to execute the created request",
        });
      });
    setIsLoading(false);
  };

  // Endpoint integration of edit submit test
  const onSubmitEditTest = async (e) => {
    setEditModal(false);
    setIsLoading(true);
    e.preventDefault();
    await axios
      .put(
        `${UPDATE_TEST}${editId}/`,
        {
          name: editCredentials.name ? editCredentials.name : oldName,
          description: editCredentials.description ? editCredentials.description : oldDesc,
        },
        {
          headers: {
            Authorization: `Token ${SubToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setEditCredentials({
          name: "",
          description: "",
        });
        showToast("Test Updated Succesfully");
        getTestLists();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Test Update Request Reject",
          text: "Failed to execute the update request",
        });
      });
    setIsLoading(false);
  };

  // Endpoint integration of show list questions
  const showListQuestions = async (id, q_name) => {
    await axios
      .get(`${GET_TEST_QUESTIONS}${id}/`, {
        headers: {
          Authorization: `Token ${SubToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setTestQuestions(response.data.questions);
        setQuestionModal(true);
        setQId(id);
        setQuestionName(q_name);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Show Questions Request Failed",
          text: "Failed to execute the get questions request",
        });
      });
  };

  const onClickImgOpen = (img) => {
    setImgState(`${IMG_URL}${img}`);
    setImgModal(true);
  };

  // Endpoint integration of delete question
  const onClickDeleteQuestion = async (id) => {
    setIsLoading(true);
    await axios
      .delete(`${DELETE_QUESTION}/${id}`, {
        headers: {
          Authorization: `Token ${SubToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        showToast("Question Deleted Successfully");
        showListQuestions(qId);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Question Delete Request Reject",
          text: "Failed to execute the delete request",
        });
      });
    setIsLoading(false);
  };

  // Endpoint integration of update test
  const onClickUpdateQuestion = async (id, type, image, description, text) => {
    setListQId(id);
    setFormData({
      type: type.toLowerCase() === "image" ? "file" : type.toLowerCase(),
      text: text,
      image: null,
      description: description,
    });
    setEditQuestionModal(true);
  };

  useEffect(() => {
    getTestLists();
  }, []);

  // Endpoint integration of submit question
  const onSubmitEditQuestion = async (e) => {
    e.preventDefault();
    setEditQuestionModal(false);
    setIsLoading(true);
    const formDataObj = new FormData();

    formDataObj.append("description", formData.description);
    if (formData.type === "text") {
      formDataObj.append("text", formData.text);
    } else if (formData.type === "file") {
      formDataObj.append("image", formData.image);
    }
    console.log("formDataObj", formDataObj);
    await axios
      .put(`${UPDATE_QUESTION}${listQId}/`, formDataObj, {
        headers: {
          Authorization: `Token ${SubToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        showListQuestions(qId);
        showToast("Question Updated Successfully");
        setFormData({
          type: "text",
          text: "",
          image: null,
          description: "",
        });
      })
      .catch((response) => {
        Swal.fire({
          icon: "error",
          title: "Update Question Request Reject",
          text: "Failed to execute the update request",
        });
      });
    setIsLoading(false);
  };

  // Endpoint integration of add question
  const onSubmitAddQuestion = async () => {
    setIsLoading(true);
    const addformDataObj = new FormData();
    const updatedMultiData = [...addMultiData];
    updatedMultiData.forEach((item, index) => {
      addformDataObj.append(
        `questions[${index}][question_type]`,
        item.type === "text" ? "TXT" : "IMG"
      );
      if (item.text) {
        addformDataObj.append(`questions[${index}][text]`, item.text);
      }
      if (item.image) {
        addformDataObj.append(`questions[${index}][image]`, item.image);
      }
      addformDataObj.append(`questions[${index}][description]`, item.description);
    });
    try {
      await axios.post(`${ADD_QUESTION}${addQid}/`, addformDataObj, {
        headers: {
          Authorization: `Token ${SubToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      showToast("Question Added Successfully");
      setAddFormData({
        type: "",
        text: "",
        image: null,
        description: "",
      });
      setAddMultiData([]);
      setAddBtnDisable(true);
      setDelBtnDisable(true);

      setAddQuestionModal(false);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Add Question Request Failed",
        text: "Failed to execute the add request",
      });
    }

    setIsLoading(false);
  };

  const onDeleteMultiData = (index) => {
    setAddMultiData((prevData) => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });
  };

  const onSubmitSaveQuestion = async (e) => {
    e.preventDefault();
    saveMultiQuestion(
      addformData.type,
      addformData.text,
      addformData.image,
      addformData.description
    );
    showToast("Question Added Successfully");
  };

  return (
    <>
      {isLoading ? <MoreAppLoader /> : null}
      <Dashboard title="Add Oral Assessment">
        <div className="assessment-table">
          <div className="d-flex justify-content-end mb-3">
            <button
              type="button"
              className="btn btn-primary"
              data-tour="1"
              style={{
                background: "linear-gradient(90deg, #56367F 0%, #3A5697 100%)",
                border: "none",
                borderRadius: "8px",
                padding: "10px 20px",

              }}
              onClick={() => setShowModal(true)}
            >
              Add Test
            </button>
          </div>
          <div data-tour="2">
            {Object.entries(listTest).length > 0 &&
              listTest.map((item, index) => {
                return (
                  <div
                    className={`bg-test-div mb-3 ${heightToggle === index && "test-height-toggle"}`}
                    key={index}
                  >
                    <div className="bg-test">
                      <div className="test-flex">
                        <div className="test-div">
                          <p className="m-0 test-caption">
                            <strong>Test Name</strong>
                          </p>
                          <p className="m-0 test-name-width">{item.name} </p>
                        </div>
                        <div className="created-div">
                          <p className="m-0 test-caption">
                            <strong>Created By</strong>
                          </p>
                          <p className="m-0">{item.created_by_email}</p>
                        </div>
                      </div>
                      <div className="action-space">
                        <div className="action-question">
                          <p className="m-0 test-caption">
                            <strong>Action</strong>
                          </p>
                          <div className="btn-action-space d-flex">
                            <button type="button" className="btn-del-assess" data-tour="4">
                              <FaTrash onClick={() => onClickDeleteTest(item.id)} />
                            </button>
                            <button type="button" className="btn-edit-assess" data-tour="5">
                              <LiaEditSolid onClick={() => onClickUpdateTest(item.id)} />
                            </button>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary test-btn-toggle"
                          style={{ background: "#50B482", border: "none" }}
                          onClick={() => {
                            onClickHeightToggle(index);
                          }}
                          data-tour="3"
                        >
                          {" "}
                          {heightToggle === index && heightState ? (
                            <MdKeyboardArrowUp />
                          ) : (
                            <MdOutlineKeyboardArrowDown />
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="test-description">
                      <p className="m-0 test-caption">
                        <strong>Test Description</strong>
                      </p>
                      <div>
                        <p className="test-content">{item.description}</p>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="mt-3 question-add-btn">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => onClickAddQuestionBtn(item.id)}
                        style={{ background: "linear-gradient(90deg, #56367F 0%, #3A5697 100%)", border: "none" }}
                      >
                        Add Question
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() => {
                          showListQuestions(item.id, item.name);
                        }}
                        style={{
                          borderRadius: "50%",
                          height: "42px",
                          width: "42px",
                        }}
                      >
                        <FaEye />
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>

          {/* Add Test Modal */}
          <div
            className={`modal fade ${showModal ? "show" : ""}`}
            style={{
              display: showModal ? "block" : "none",
              background: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <form
                  onSubmit={(e) => {
                    onSubmitTest(e);
                  }}
                >
                  <div className="modal-header" style={{ background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)', color: "white" }}>
                    <h5 className="modal-title" id="exampleModalLabel">
                      Add Test
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => {
                        setShowModal(false);

                      }}

                      style={{ background: `url(${customCloseIcon}) no-repeat center center`, backgroundSize: 'cover', border: 'none', opacity: "1" }}
                    >

                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="mb-3">
                      <label htmlFor="email" className="small mb-1" >
                        Test Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        maxLength={"200"}
                        value={addCredentials.name}
                        onChange={(e) => onChangeCredentials(e)}
                        required
                        style={{ backgroundColor: "#F0F3F6", border: "none", marginTop: "10px" }}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="selectOption" className="small mb-1">
                        Test Description <span className="required">*</span>
                      </label>
                      <textarea
                        className="form-control text-area-test"
                        maxLength={"1000"}
                        name="description"
                        value={addCredentials.description}
                        placeholder="Write test description..."
                        onChange={(e) => onChangeCredentials(e)}
                        required
                        style={{ backgroundColor: "#F0F3F6", border: "none", marginTop: "10px" }}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setShowModal(false);
                        setAddCredentials({ name: "", description: "" });

                      }}



                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success"
                      style={{ background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)', border: "none" }}
                    >
                      Add Test
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* Update Test Modal */}
          <div
            className={`modal fade ${editModal ? "show" : ""}`}
            style={{
              display: editModal ? "block" : "none",
              overflowY: "hidden",
              background: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <form
                  onSubmit={(e) => {
                    onSubmitEditTest(e);
                  }}
                >
                  <div className="modal-header" style={{ background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)', color: "white" }}>
                    <h5 className="modal-title" id="exampleModalLabel">
                      Update Test
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => {
                        setEditModal(false);

                      }}
                      style={{ background: `url(${customCloseIcon}) no-repeat center center`, backgroundSize: 'cover', border: 'none', opacity: "1" }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="mb-3">
                      <label htmlFor="email" className="small mb-1">
                        Test Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        maxLength={"200"}
                        value={editCredentials.name}
                        onChange={(e) => onChangeEditCredentials(e)}
                        style={{ backgroundColor: "#F0F3F6", border: "none", marginTop: "10px" }}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="selectOption" className="small mb-1">
                        Test Description <span className="required">*</span>
                      </label>
                      <textarea
                        className="form-control text-area-test"
                        maxLength={"1000"}
                        name="description"
                        value={editCredentials.description}
                        placeholder="Write test description..."
                        onChange={(e) => onChangeEditCredentials(e)}
                        style={{ backgroundColor: "#F0F3F6", border: "none", marginTop: "10px" }}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setEditModal(false);
                        setEditCredentials({ name: "", description: "" });
                      }}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success"
                      style={{ background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)', border: "none" }}
                    >
                      Edit Test
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* View Questions Modal */}
          <div
            className={`modal fade ${questionModal ? "show" : ""}`}
            style={{
              display: questionModal ? "block" : "none",
              overflowY: "hidden",
              background: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable custom-scrollbar">
              <div className="modal-content">
                <form
                  onSubmit={(e) => {
                    onSubmitEditTest(e);
                  }}
                >
                  <div className="modal-header" style={{ background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)', color: "white" }}>
                    <h5 className="modal-title" id="exampleModalLabel">
                      {questionName} Questions
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => {
                        setQuestionModal(false);

                      }}
                      style={{ background: `url(${customCloseIcon}) no-repeat center center`, backgroundSize: 'cover', border: 'none', opacity: "1" }}
                    ></button>
                  </div>
                  <div className="modal-body ">
                    <div
                      className="table-responsive "
                      style={{ height: "400px", overflowY: "auto" }}
                    >
                      <table className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Type</th>
                            <th scope="col">Title</th>
                            <th scope="col">Image</th>
                            <th scope="col">Question</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(testQuestions).length > 0 &&
                            testQuestions.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.type}</td>
                                  <td>
                                    {item.text === "" || item.text === null ? "null" : item.text}
                                  </td>
                                  <td>
                                    {item.image ? (
                                      <button
                                        type="button"
                                        className="img-faeye-icon"
                                        onClick={() => {
                                          onClickImgOpen(item.image);
                                        }}
                                      >
                                        <FaEye />
                                      </button>
                                    ) : (
                                      "null"
                                    )}
                                  </td>
                                  <td>{item.description}</td>
                                  <td>
                                    <div className="d-flex">
                                      <button type="button" className="btn-del-assess">
                                        <FaTrash onClick={() => onClickDeleteQuestion(item.id)} />
                                      </button>
                                      <button type="button" className="btn-edit-assess">
                                        <LiaEditSolid
                                          onClick={() =>
                                            onClickUpdateQuestion(
                                              item.id,
                                              item.type,
                                              item.image,
                                              item.description,
                                              item.text
                                            )
                                          }
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setQuestionModal(false);
                        setEditCredentials({ name: "", description: "" });
                      }}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* View specific question details modal */}
          <div
            className={`modal fade ${imgModal ? "show" : ""}`}
            style={{
              display: imgModal ? "block" : "none",
              overflowY: "hidden",
              background: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header" style={{ background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)', color: "white" }}>
                  <h5 className="modal-title" id="exampleModalLabel">
                    Test {questionName} Questions
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => {
                      setImgModal(false);
                    }}
                  ></button>
                </div>
                <div className="modal-body">
                  <img src={`${imgState}`} className="img-fluid" alt="question-img" />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      setImgModal(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Edit Question Modal */}
          <div
            className={`modal fade ${editQuestionModal ? "show" : ""}`}
            style={{
              display: editQuestionModal ? "block" : "none",
              overflowY: "hidden",
              background: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header" style={{ background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)', color: "white" }}>
                  <h5 className="modal-title" id="exampleModalLabel">
                    Edit Question
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => {
                      setEditQuestionModal(false);
                    }}
                    style={{ background: `url(${customCloseIcon}) no-repeat center center`, backgroundSize: 'cover', border: 'none', opacity: "1" }}
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={(e) => onSubmitEditQuestion(e)} encType="multipart/form-data">
                    <div className="my-3">
                      <label>
                        <b>Select Question Type:</b>
                      </label>
                      <select
                        name="type"
                        className="form-control mt-2"
                        value={formData.type}
                        disabled
                        style={{ backgroundColor: "#F0F3F6", border: "none", marginTop: "10px" }}
                      >
                        {" "}
                        <option value="">Select an option</option>
                        <option value="text">Text</option>
                        <option value="file">Image</option>
                      </select>
                    </div>
                    {formData.type === "text" && (
                      <div className="mt-3">
                        <label>
                          <b>Title:</b>
                        </label>
                        <input
                          type="text"
                          name="text"
                          className="form-control mt-2"
                          value={formData.text}
                          onChange={handleInputChange}
                          required
                          style={{ backgroundColor: "#F0F3F6", border: "none", marginTop: "10px" }}
                        />
                      </div>
                    )}
                    {formData.type === "file" && (
                      <div className="mt-3 mb-3">
                        <label>
                          <b>Image:</b>
                        </label>
                        <input
                          type="file"
                          name="image"
                          className="form-control mt-2"
                          onChange={handleInputChange}
                          accept="image/*"
                          required
                          style={{ backgroundColor: "#F0F3F6", border: "none", marginTop: "10px" }}
                        />
                      </div>
                    )}
                    <div className="mt-3 mb-3">
                      <label>
                        <b>Question:</b>
                      </label>
                      <textarea
                        name="description"
                        className="form-control mt-2"
                        value={formData.description}
                        required
                        onChange={handleInputChange}
                        style={{ backgroundColor: "#F0F3F6", border: "none", marginTop: "10px" }}
                      />
                    </div>
                    <div className="d-flex justify-content-end" style={{ columnGap: "1rem" }}>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          setEditQuestionModal(false);
                          setFormData({
                            type: "text",
                            text: "",
                            image: null,
                            description: "",
                          });
                        }}
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)', border: "none" }}
                      >
                        Edit Question
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* Add Question Modal */}
          <div
            className={`modal fade ${addQuestionModal ? "show" : ""}`}
            style={{
              display: addQuestionModal ? "block" : "none",
              overflowY: "hidden",
              background: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header" style={{ background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)', color: "white" }}>
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add Question
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => {
                      setAddQuestionModal(false);
                      setAddFormData({
                        type: "",
                        text: "",
                        image: null,
                        description: "",
                      });
                      setAddMultiData([]);
                      setAddBtnDisable(true);
                      setDelBtnDisable(true);
                    }}
                    style={{ background: `url(${customCloseIcon}) no-repeat center center`, backgroundSize: 'cover', border: 'none', opacity: "1" }}
                  ></button>
                </div>
                <div className="modal-body modal-body-scrollbar">
                  {addMultiData.length > 0 && (
                    <div
                      className="table-responsive"
                      style={{ height: "230px", overflowY: "scroll" }}
                    >
                      <h5 style={{ textAlign: "center", fontWeight: "bold" }}>Text Type</h5>
                      <table className="table table-hover mt-4" style={{ minWidth: "610px" }}>
                        <thead>
                          <tr>
                            <th scope="col">Title</th>
                            <th scope="col">Question</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {addMultiData.map((item, index) => {
                            if (item.type === "text") {
                              return (
                                <tr id={index} key={index}>
                                  <td style={{ width: "380px" }}>
                                    {item.text === "" ? "null" : item.text}
                                  </td>
                                  <td style={{ width: "380px" }}>{item.description}</td>
                                  <td>
                                    <button
                                      className="btn-del-assess"
                                      onClick={() => {
                                        onDeleteMultiData(index);
                                      }}
                                    >
                                      <FaTrash />
                                    </button>
                                  </td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                      <Divider className="mt-4">***</Divider>
                      <h5 className="mt-3" style={{ textAlign: "center", fontWeight: "bold" }}>
                        Image Type
                      </h5>
                      <table className="table table-hover mt-3" style={{ minWidth: "610px" }}>
                        <thead>
                          <tr>
                            <th scope="col">Image Name</th>
                            <th scope="col">Question</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {addMultiData.map((item, index) => {
                            if (item.type === "file") {
                              return (
                                <tr id={index} key={index}>
                                  <td style={{ width: "380px" }}>{item.image.name}</td>
                                  <td style={{ width: "380px" }}>{item.description}</td>
                                  <td>
                                    <button
                                      className="btn-del-assess"
                                      onClick={() => {
                                        onDeleteMultiData(index);
                                      }}
                                    >
                                      <FaTrash />
                                    </button>
                                  </td>
                                </tr>
                              );
                            } else {
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}

                  <form
                    className="mt-2"
                    onSubmit={(e) => onSubmitSaveQuestion(e)}
                    encType="multipart/form-data"

                  >
                    <div className="my-3">
                      <label>
                        <b>Select Question Type:</b>
                      </label>
                      <select
                        name="type"
                        className="form-control mt-2"
                        value={addformData.type}
                        onChange={addhandleInputChange}
                        style={{ backgroundColor: "#F0F3F6", border: "none", marginTop: "10px", color: "#989898" }}
                      >
                        {" "}
                        <option value="">Select an option</option>
                        <option value="text">Text</option>
                        <option value="file">Image</option>
                      </select>
                    </div>
                    {addformData.type === "text" && (
                      <div className="mt-3">
                        <label>
                          <b>Title:</b>
                        </label>
                        <input
                          type="text"
                          name="text"
                          className="form-control mt-2"
                          value={addformData.text}
                          onChange={addhandleInputChange}
                          required
                          style={{ backgroundColor: "#F0F3F6", border: "none", marginTop: "10px" }}
                        />
                      </div>
                    )}
                    {addformData.type === "file" && (
                      <div className="mt-3 mb-3">
                        <label>
                          <b>Image:</b>
                        </label>
                        <input
                          type="file"
                          name="image"
                          className="form-control mt-2"
                          onChange={addhandleInputChange}
                          accept="image/*"
                          required
                          style={{ backgroundColor: "#F0F3F6", border: "none", marginTop: "10px" }}
                        />
                      </div>
                    )}
                    <div className="mt-3 mb-3">
                      <label>
                        <b>Question:</b>
                      </label>
                      <textarea
                        name="description"
                        className="form-control mt-2"
                        value={addformData.description}
                        required
                        onChange={addhandleInputChange}
                        style={{ backgroundColor: "#F0F3F6", border: "none", marginTop: "10px" }}
                      />
                    </div>
                    <div
                      className="d-flex flex-sm-row flex-row justify-content-end modal-footer-test"
                      style={{ columnGap: "1rem", rowGap: "1rem" }}
                    >
                      <button
                        type="button"
                        className="btn btn-secondary modal-btn-font"
                        onClick={() => {
                          setAddQuestionModal(false);
                          setAddFormData({
                            type: "",
                            text: "",
                            image: null,
                            description: "",
                          });
                          setAddMultiData([]);
                          setAddBtnDisable(true);
                          setDelBtnDisable(true);
                        }}
                        style={{ opacity: "1" }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success modal-btn-font"
                        style={{ background: "#50B482", border: "none", opacity: "2" }}
                      >
                        Add
                      </button>
                      <button
                        type="submit"
                        className="btn  modal-btn-font"
                        disabled={delBtnDisable}
                        onClick={() => {
                          setAddMultiData([]);
                          setDelBtnDisable(true);
                          setAddBtnDisable(true);
                        }}
                        style={{ background: "#E53946", border: "none", color: "white", opacity: "1" }}
                      >
                        Reset
                      </button>
                      <button
                        type="button"
                        className="btn modal-btn-font"
                        disabled={addBtnDisable}
                        onClick={() => {
                          onSubmitAddQuestion();
                        }}
                        style={{
                          background: "linear-gradient(180deg, #56367F 0%, #3A5697 100%)", border: "none", color: "white", opacity: "1"
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* Add Attention Modal */}
          <div
            className={`modal fade ${showAttentionModal ? "show" : ""}`}
            style={{
              display: showAttentionModal ? "block" : "none",
              overflowY: "hidden",
              background: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header" style={{ background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)', color: "white" }}>
                  <h5 className="modal-title text-center" id="exampleModalLabel">
                    Attention
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => {
                      setShowAttentionModal(false);
                    }}
                    style={{ background: `url(${customCloseIcon}) no-repeat center center`, backgroundSize: 'cover', border: 'none', opacity: "1" }}
                  ></button>
                </div>
                <div className="modal-body modal-body-scrollbar text-center py-5">
                  <h4 style={{ fontWeight: "700" }}>
                    Please add atleast 1 question to this test.{" "}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    </>
  );
};

export default OralAssessment;
