import React from "react";
import AuthProvider from "./context/AuthContext";
import SubAdminStack from "./MainAdminStack/Stack";
import { BrowserRouter as Router } from "react-router-dom";
import { TourProvider, useTour } from "@reactour/tour";
import steps from "./components/steps";

function App() {
  const { currentStep } = useTour();

  return (
    <Router>
      <AuthProvider>
        <TourProvider steps={steps}>
          <SubAdminStack />
        </TourProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
