import React, { useEffect, useState } from "react";
import Dashboard from "./Layout/dashboard";
import Graph from "./graph";
import validusers from "../../../assets/img/valid-users.png";
import assessments from "../../../assets/img/assessments.png";
import predictive from "../../../assets/img/predictive-reports.png";
import totalcvs from "../../../assets/img/PI-tests.png";
import DashFoot from "../../AdminView/Dashboard/dashFoot";
import Commonicon from "../../../assets/img/green-icon.png"
import {
  GET_SUB_ADMIN_TOTAL_USERS,
  GET_SUB_ADMIN_TOTAL_CV,
  GET_SUB_ADMIN_REPORTS,
  GET_SUBADMIN_PREDICTIVE_REPORTS,
} from "../../../Auth_API";
import { UserLogin } from "../../../context/AuthContext";
import axios from "axios";
import CountUp from "react-countup";
import { BsPeopleFill } from "react-icons/bs";
import { RiFilePaperFill } from "react-icons/ri";
import { SiTeamspeak } from "react-icons/si";
import { FaChartPie } from "react-icons/fa";
import { useTour } from "@reactour/tour";

// Functions to disable and enable scrolling
const disableScroll = () => {
  document.body.style.overflow = 'hidden';
};

const enableScroll = () => {
  document.body.style.overflow = '';
};

const Balance = () => {
  const { SubToken } = UserLogin();
  const [validUsers, setValidUsers] = useState(0);
  const [validCV, setValidCV] = useState(0);
  const [validReports, setValidReports] = useState(0);
  const [validPredictiveCount, setValidPredictiveCount] = useState(0);
  const { isOpen, currentStep, setCurrentStep, setIsOpen } = useTour();
  let Organization_ID = localStorage.getItem("Sub_Admin_Id");

  useEffect(() => {
    console.log("current step", currentStep);
    if (currentStep > 2) {
      setCurrentStep(0);
    }
  }, [currentStep]);

  useEffect(() => {
    const handleBodyScroll = () => {
      if (isOpen) {
        disableScroll();
      } else {
        enableScroll();
        setCurrentStep(5);
      }
    };

    handleBodyScroll();

    return () => {
      enableScroll(); // Ensure scroll is enabled when the component unmounts
    };
  }, [isOpen, setCurrentStep]);

  const data = [
    {
      title: "Valid User",
      value: validUsers,
      icon: validusers,
    },
    {
      title: "Overall Assessment",
      value: validReports,
      icon: assessments,
    },
    {
      title: "Total CV's",
      value: validCV,
      icon: totalcvs,
    },
    {
      title: "Predictive Reports",
      value: validPredictiveCount,
      icon: predictive,
    },
  ];

  const getTotalValidUsers = async () => {
    await axios
      .get(`${GET_SUB_ADMIN_TOTAL_USERS}`, {
        headers: {
          Authorization: `Token ${SubToken}`,
        },
      })
      .then((response) => {
        setValidUsers(response.data.total_users);
      })
      .catch((error) => { });
  };

  const getTotalCV = async () => {
    await axios
      .get(`${GET_SUB_ADMIN_TOTAL_CV}`, {
        headers: {
          Authorization: `Token ${SubToken}`,
        },
      })
      .then((response) => {
        setValidCV(response.data.total_cvs);
      })
      .catch((error) => { });
  };

  const getTotalReport = async () => {
    await axios
      .get(`${GET_SUB_ADMIN_REPORTS}`, {
        headers: {
          Authorization: `Token ${SubToken}`,
        },
      })
      .then((response) => {
        setValidReports(response.data.total_test_submissions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPredictiveIndexCount = async () => {
    await axios
      .get(`${GET_SUBADMIN_PREDICTIVE_REPORTS}/${Organization_ID}`, {
        headers: {
          Authorization: `Token ${SubToken}`,
        },
      })
      .then((response) => {
        setValidPredictiveCount(response.data.count);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTotalValidUsers();
    getTotalCV();
    getTotalReport();
    getPredictiveIndexCount();
  }, []);

  return (
    <>
      <Dashboard title="Dashboard">
        <div className="background">
          <div className="dashboard-top" data-tour="6">
            {data.map((item, index) => {
              return (
                <div className="total shadow-sm" key={index}>
                  <div className="icon-text-container">
                    <img src={Commonicon} alt="Common icon" className="common-icon" />
                    <p className="cv-title mb-3">
                      {item.title}
                    </p>
                  </div>
                  <h3 className="content valid-user" style={{ marginLeft: "30px" }}>
                    <CountUp end={item.value} />
                  </h3>
                  <img src={item.icon} alt={`${item.title} icon`} className="item-icon" />
                </div>
              );
            })}
          </div>
          <Graph />
          <DashFoot />
        </div>
      </Dashboard>
    </>
  );
};

export default Balance;
