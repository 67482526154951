import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { UserLogin } from "../../../context/AuthContext";
import { ADMIN_URL } from "../../../Auth_API";

const ReportGraph = () => {
  const {SubToken} = UserLogin();
  let Organization_ID = localStorage.getItem("Sub_Admin_Id");

  const [options, setOptions] = useState({
    chart: {
      id: "basic-bar",
      events: {
        zoomable: false,
        pannable: false,
        markerClick: false,
        selection: false,
      },
    },
    xaxis: {
      categories: [],
    },
    colors: ["#56367F"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 1,
        opacityTo: 0.6,
        stops: [0, 100],
      },
    },
    toolbar: {
      show: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      borderColor: '#e0e0e0',
      strokeDashArray: 4,
    },
  });

  const [series, setSeries] = useState([
    {
      name: "Reports",
      data: [],
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${ADMIN_URL}/count_test_submissions_per_month/${Organization_ID}/`,
          {
            headers: {
              Authorization: `Token ${SubToken}`,
          },
          });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(data, "gdhghdsghdsf")
        processData(data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []); 

  
  const processData = (data) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const categories = data.map(item => {
      const date = new Date(item.month);
      return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
    });
    const seriesData = data.map(item => item.count);

    setOptions(prevOptions => ({
      ...prevOptions,
      xaxis: { categories },
    }));

    setSeries([{ name: "Reports", data: seriesData }]);
  };

  return (
    <div id="chart">
      <ReactApexChart
        className="apexcharts-canvas"
        options={options}
        series={series}
        type="area"
        height={300}
      />
      <div style={{ position: "relative" }} className="d-flex justify-content-center">
        <p className="graph-caption" style={{ position: "absolute", top: "-10px" }}>
          Total Reports
        </p>
      </div>
    </div>
  );
}

export default ReportGraph;
