import React from "react";
import { UserLogin } from "../../../context/AuthContext";

function ReportSection2() {
  const { selectedAudioReport } = UserLogin();
  const nicelyDone = selectedAudioReport?.analysis_data?.detailedMetrics?.strengths;
  const improvementArea = selectedAudioReport?.analysis_data?.detailedMetrics?.improvementAreas;
  // console.log(improvementArea, "improvementArea")

  return (
    <>
      <div className="row mb-3" style={{ padding: "15px 0 0 0" }}>
        <div className="col-md-6 mb-3">
          <div
            className="card"
            style={{ borderRadius: "25px", border: "2px solid #b7b7b7", height: "100%" }}
          >
            <div className="card-body" style={{ padding: "35px" }}>
              <h4 className="card-title" style={{ fontWeight: "600" }}>
                Nicely done
              </h4>
              {nicelyDone && nicelyDone?.length > 0 ? (
                <p className="card-text my-4">
                  <ul className="checklist d-flex align-items-start flex-column">
                    {nicelyDone.map((strength, index) => (
                      <li key={index} className="my-1">
                        {strength}
                      </li>
                    ))}
                  </ul>
                </p>
              ) : (
                <p>No strength found.</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div
            className="card"
            style={{ borderRadius: "25px", border: "2px solid #b7b7b7", height: "100%" }}
          >
            <div className="card-body" style={{ padding: "35px" }}>
              <h4 className="card-title" style={{ fontWeight: "600" }}>
                Things to improve
              </h4>
              {improvementArea && improvementArea?.length > 0 ? (
                <p className="card-text my-4">
                  <ul className="checklist-2">
                    {improvementArea.map((improvement, index) => (
                      <li key={index} className="my-1">
                        {improvement}
                      </li>
                    ))}
                  </ul>
                </p>
              ) : (
                <p>No improvement found.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportSection2;
