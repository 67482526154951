import React from 'react';
import imgPI1 from "../../../../assets/img/pi-7.png";
import imgPI2 from "../../../../assets/img/pi-9.png";
import { UserLogin } from '../../../../context/AuthContext';

function Section5() {
    const { piSelectedReport } = UserLogin();

    return (
        <div className='pi-section-container'>
            <div className="row row-cols-1 row-cols-md-2 row-cols-sm-1 row-personal-dev">
                <div className="col">
                    <div className="card pi-card py-4">
                        <div className='row pi-skills-row'>
                            <div className='col col-sm-4 pi-col-5 pi-personal-col'>
                                <img src={imgPI1} className="img-fluid pi-personal-img" alt="..." />
                            </div>
                            <div className='col col-sm-8 py-4 pi-col-7 pi-personal-col'>
                                <p className="card-title pi-card-title pi-personal-title">Skills Training</p>
                                <div className="progress-container">
                                    <div className="progress" role="progressbar" aria-label="Basic example"
                                        aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                                        <div
                                            className="progress-bar"
                                            style={{ width: "50%", background: "#56367F", borderRadius: "30px" }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row py-3 pi-skills-padding'>
                            <p className="card-text pi-card-text mt-4">
                                {piSelectedReport?.analysis_data?.personal_development?.skills_training}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card pi-card py-4 pi-prsonal-card">
                        <div className='row pi-skills-row'>
                            <div className='col col-sm-4 pi-col-5 pi-personal-col'>
                                <img src={imgPI2} className="img-fluid pi-personal-img" alt="..." />
                            </div>
                            <div className='col col-sm-8 py-4 pi-col-7 pi-personal-col'>
                                <p className="card-title pi-card-title pi-personal-title">Growth Opportunities</p>
                                <div className="progress-container">
                                    <div className="progress" role="progressbar" aria-label="Basic example"
                                        aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                                        <div
                                            className="progress-bar"
                                            style={{ width: "50%", background: "#56367F", borderRadius: "30px" }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row py-3 pi-skills-padding'>
                            <p className="card-text pi-card-text mt-4">
                                {piSelectedReport?.analysis_data?.personal_development?.growth_opportunities}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section5