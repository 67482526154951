const steps = [
  //0
  {
    selector: '[data-tour="6"]',
    content: (
      <>
        <h3 className="step-heading">Digital stats overview</h3>
        <p>Overall Statistics information of onboardiq app.</p>
      </>
    ),
    disableInteraction: true,
  },
  //1
  {
    selector: '[data-tour="7"]',
    content: (
      <>
        <h3 className="step-heading">User's signup graph</h3>
        <p>This graph shows the registered users in each month.</p>
      </>
    ),
    disableInteraction: true,
  },
  //2
  {
    selector: '[data-tour="8"]',
    content: (
      <>
        <h3 className="step-heading">Assessment table</h3>
        <p>User detailed information table with assessment submissions.</p>
      </>
    ),
    disableInteraction: true,
  },

  //3
  {
    selector: '[data-tour="1"]',
    content: (
      <>
        <h3 className="step-heading">Step 1:</h3>
        <p>Your can add multiple tests from here.</p>
      </>
    ),
    highlightedSelectors: [".ReactModal__Content"],
    mutationObservables: [".ReactModal__Overlay"],
    disableInteraction: true,
  },
  //4
  {
    selector: '[data-tour="2"]',
    content: (
      <>
        <h3 className="step-heading">Step 2:</h3>
        <p>List of tests and their description.</p>
      </>
    ),
    disableInteraction: true,
  },
  //5
  {
    selector: '[data-tour="3"]',
    content: (
      <>
        <h3 className="step-heading">Step 3:</h3>
        <p>Dropdown to show a test description and number of questions available.</p>
      </>
    ),
    disableInteraction: true,
  },
  //6
  {
    selector: '[data-tour="4"]',
    content: (
      <>
        <h3 className="step-heading">Step 4:</h3>
        <p>Delete any test from here.</p>
      </>
    ),
    disableInteraction: true,
  },
  //7
  {
    selector: '[data-tour="5"]',
    content: (
      <>
        <h3 className="step-heading">Step 5:</h3>
        <p>Modify the test details.</p>
      </>
    ),
    disableInteraction: true,
  },
];

export default steps;
