import React from "react";
import { FaBars } from "react-icons/fa";
import { UserLogin } from "../../../../context/AuthContext";
// import shape from "../../../../assets/img/shape.png";
import profile from "../../../../assets/img/profile-new.png";

const Topnav = ({ title, onOpen }) => {
  const { adminEmail } = UserLogin();

  
  return (
    <div className="top-nav d-flex align-items-center justify-content-between">
      <div className="title-top">
        <h1 className="title m-0">{title}</h1>
      </div>
      <div className="d-flex align-items-center gap-1 ">
        {/* <img src={shape}  className="shape-image  "/> */}
        <img src={profile} className="profile-image"/>
        <p className="admin-email email-in-topnav" >{adminEmail.split("@")[0]}</p>
        <div className="dropdown">
          <button
            className="btn btn-toggle"
            type="button"
            onClick={() => {
              onOpen();
            }}
          >
            <FaBars />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Topnav;
