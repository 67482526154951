import React from 'react';
import { UserLogin } from '../../../../context/AuthContext';
import Lottie from 'lottie-react';

function Section1() {
  const { piSelectedReport } = UserLogin();

  return (
    <>
      <div className='pi-section-container'>
        <div className="pi-card card mb-3" style={{ width: "100%", padding: "60px 100px",backgroundColor:"white"}}>
          <div className="row g-0 pi-dynamic-row">
            <div className="col-md-5 col-sm-12 pi-col-5">
              <div className="">
                <Lottie
                  animationData={require("../../../../assets/animation/pi-rocket.json")}
                  loop
                  autoplay
                  className="img-fluid dynamic-img pi-animation"
                />
              </div>
            </div>
            <div className="col-md-7 col-sm-12 pi-col-7">
              <div className="card-body ">
                <p className="card-title pi-card-title">Team Dynamics</p>
                <div className="progress-container">
                  <div className="progress" role="progressbar" aria-label="Basic example"
                    aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                    <div
                      className="progress-bar"
                      style={{ width: "50%", background: "#56367F", borderRadius: "50px" }}>
                    </div>
                  </div>
                </div>
                <br />
                <p className="card-text pi-card-text mt-4">
                  {piSelectedReport?.analysis_data?.team_dynamics || "Ah, the mysterious case of the missing teamwork ability,  looks like we've got a lone wolf here!"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Section1