import React from "react";
import { UserLogin } from "../../../context/AuthContext";
import proMic from "../../../assets/img/pronoun-mic.png";
import proDifiMic from "../../../assets/img/diffi-proun.png";

function ReportSection5_3() {
  const { selectedAudioReport } = UserLogin();
  const punctuationMistakes =
    selectedAudioReport?.analysis_data?.detailedMetrics?.punctuationMistakes;

  const handleAudioClick = (word) => {
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(word);
      utterance.lang = "en-US";
      window.speechSynthesis.speak(utterance);
    } else {
      console.log("Text-to-speech synthesis not supported in this browser.");
    }
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12" style={{ padding: "15px 0 0 0" }}>
          <div className="card" style={{ borderRadius: "25px", background: "white" }}>
            <div className="card-body mx-2">
              <div style={{ fontWeight: "bold", padding: "15px 0" }}>
                <img
                  src={proDifiMic}
                  alt="difficult pronounciation mic"
                  className="proun-img"
                  style={{ cursor: "pointer" }}
                />
                <h8 className="card-title mx-3">Most difficult words to pronounce</h8>
              </div>
              <div className="table-responsive" style={{ padding: "0 30px 0 30px" }}>
                <table className="table table-borderless">
                  <thead style={{ color: "#606070" }}>
                    <tr>
                      <th scope="col" style={{ textAlign: "left" }}>
                        Word
                      </th>
                      <th scope="col" style={{ textAlign: "left" }}>
                        Suggestions
                      </th>
                      <th scope="col" style={{ textAlign: "right" }}>
                        Audio
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {punctuationMistakes &&
                      Object.entries(punctuationMistakes).map(([mistake, suggestion], index) => (
                        <tr key={index}>
                          <td style={{ textAlign: "left" }}>{mistake}</td>
                          <td style={{ textAlign: "left" }}>{suggestion}</td>
                          <td style={{ textAlign: "right" }}>
                            <img
                              src={proMic}
                              alt="pronounciation mic"
                              className="proun-img-mic"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleAudioClick(suggestion)}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportSection5_3;
