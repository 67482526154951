import React from 'react';
import imgPI3_1 from "../../../../assets/img/pi-13.png";
import { UserLogin } from '../../../../context/AuthContext';

function Section4() {
    const { piSelectedReport } = UserLogin();

    return (
        <div className='pi-section-container'>
            {/* <p className='pi-team-dynamics-heading'>organizational fit</p> */}
            <div className="pi-card card mb-3" style={{ width: "100%", padding: "60px 100px",backgroundColor:"white" }}>
                <div className="row g-0 pi-dynamic-row">
                    <div className="col-md-5 pi-col-5">
                        <img src={imgPI3_1} className="img-fluid pi-img-org" alt="..." />
                    </div>
                    <div className="col-md-7 pi-col-7">
                        <div className="card-body">
                            <p className="card-title pi-card-title">Organizational Fit</p>
                            <div className="progress-container">
                                <div className="progress" role="progressbar" aria-label="Basic example"
                                    aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                                    <div
                                        className="progress-bar"
                                        style={{ width: "60%", background: "#56367F", borderRadius: "30px" }}>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <p className="card-text pi-card-text mt-4">
                                {piSelectedReport?.analysis_data?.organizational_fit ||
                                    "Looks like this user is composing their own symphony! With no description in sight, they might not quite sync up with organizations that value meticulous planning and structured execution. Maybe a more free-flowing environment is their forte!"}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section4