import React, { useState, useEffect } from "react";
import UserGraph from "./userGraph";
import ReportGraph from "./reportGraph";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { UserLogin } from "../../../context/AuthContext";
import { ADMIN_URL } from "../../../Auth_API";
import close from "../../../assets/img/cross.png";

const Graph = () => {
  const [cvData, setCvData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const { SubToken } = UserLogin();
  let Organization_ID = localStorage.getItem("Sub_Admin_Id");

  useEffect(() => {
    fetch(`${ADMIN_URL}/users_progress/${Organization_ID}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${SubToken}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        const processedData = data.map(user => ({
          name: user.user_name,
          email: user.user_email,
          similarity: `${user.progress_percentage}%`,
        }));
        setCvData(processedData);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [SubToken, Organization_ID]);

  const handleViewAll = () => {
    setIsExpanded(true);
  };

  const defaultCvData = cvData.slice(0, 3);
  const [tab, setTab] = useState("tab1");
  const onTabClick = (val) => {
    setTab((prevTab) => {
      if (prevTab !== val) {
        return val;
      }
      return prevTab;
    });
  };

  const getColor = (percentage) => {
    const value = parseInt(percentage);
    if (value <= 30) return "red";
    else if (value <= 50) return "yellow";
    else if (value <= 70) return "#2980b9";
    else return "green";
  };

  return (
    <div className="transactions">
      <div className="price-side shadow-sm" data-tour="7">
        <div className="d-xl-flex d-lg-flex d-md-flex d-sm-flex justify-content-between">
          <div>
            <p className="caption-statistics" >Current Statistics</p>
          </div>
          <div className="d-flex my-xl-0 my-lg-0 my-md-0 my-sm-0 my-3 gap-2">
            <button
              className="btn-deposit d-flex align-items-center"
              type="button"
              style={{ width: "90px" }}
              onClick={() => onTabClick("tab1")}
            >
              Users
            </button>
            <button
              className="btn-deposit"
              type="button"
              style={{ width: "90px" }}
              onClick={() => onTabClick("tab2")}
            >
              Reports
            </button>
          </div>
        </div>
        <div className="img-area">
          {tab === "tab1" && <UserGraph />}
          {tab === "tab2" && <ReportGraph />}
        </div>
      </div>

      <div className="trans-side shadow-sm">
        <p className="caption-statistics mb-1">User Progress</p>
        <svg style={{ position: 'absolute', width: 0, height: 0 }}>
          <defs>
            <linearGradient id="gradientColor" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style={{ stopColor: '#56367F', stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: '#3A5697', stopOpacity: 1 }} />
            </linearGradient>
          </defs>
        </svg>
        {defaultCvData.map((item, index) => (
          <div
            className="mt-4 user-progress-container"
            key={index}
          >
            <div className="d-flex gap-3 align-items-center">
              <span className="dollar">{item.name.charAt(0)}</span>
              <div>
                <p className="caption mb-0">{item.name}</p>
                <p className="date-time">{item.email}</p>
              </div>
            </div>
            <div className="trans-price">
              <div style={{ width: 60, height: 60 }}>
                <CircularProgressbar
                  value={parseInt(item.similarity)}
                  text={`${item.similarity}`}
                  styles={buildStyles({
                    pathColor: `url(#gradientColor)`,
                    textColor:'black',
                  })}
                />
              </div>
            </div>
          </div>
        ))}
        <button
          type="button"
          className="mt-md-4 btn btn-block"
          onClick={handleViewAll}
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          View All
        </button>
      </div>

      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header" style={{ background: "linear-gradient(180deg, #56367F 0%, #3A5697 100%)", color: "white" }}>
              <h5 className="modal-title text-center" id="exampleModalLabel">User Progress</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  margin: 0, opacity:"1"
                }}
              >
                <img
                  src={close} // Adjust the path to your icon file
                  alt="Close"
                  style={{
                    width: '20px',  // Adjust the size as needed
                    height: '20px',
                    opacity:"1" // Adjust the size as needed
                  }}
                />
              </button>
            </div>
            <div className="modal-body" style={{ maxHeight: "600px", overflow: "auto" }}>
              <svg style={{ position: 'absolute', width: 0, height: 0 }}>
                <defs>
                  <linearGradient id="gradientColorModal" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style={{ stopColor: '#56367F', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: '#3A5697', stopOpacity: 1 }} />
                  </linearGradient>
                </defs>
              </svg>
              {cvData.map((item, index) => (
                <div className="mt-4 user-progress-grid" key={index}>
                  <div className="user-details">
                    <span className="dollar">{item.name.charAt(0)}</span>
                    <div className="user-info">
                      <p className="caption mb-0">{item.name}</p>
                      <p className="date-time">{item.email}</p>
                    </div>
                  </div>
                  <div className="trans-price user-profile-progress">
                    <div style={{ width: 60, height: 60 }}>
                      <CircularProgressbar
                        value={parseInt(item.similarity)}
                        text={`${item.similarity}`}
                        styles={buildStyles({
                          pathColor: `url(#gradientColorModal)`,
                          textColor: getColor(item.similarity),
                        })}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            <div className="modal-footer" style={{ background: "linear-gradient(180deg, #56367F 0%, #3A5697 100%)" }}>
              {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Graph;
